import { Text } from "@inlet/react-pixi";
import { Container as ContainerA } from "@inlet/react-pixi/animated";
import { TextStyle } from "@pixi/text";
import {
  config,
  useChain,
  useSpring,
  useSpringRef,
  useTransition,
} from "@react-spring/core";

import React, { useCallback, useMemo } from "react";

import { menuList } from "../data/videoList";

import PBlackMask from "./PBlackMask";
import { PBtnX } from "./PBtnX";

const titleStyle = new TextStyle({
  fill: 0xffffff,
  fontSize: 32,
  fontWeight: 300,
  align: "center",
  fontFamily: "Noto Sans TC",
});

const copyRightTextStyle = new TextStyle({
  fill: 0xffffff,
  fontSize: 13,
  lineHeight: 19,
  align: "left",
  fontFamily: "Noto Sans TC",
});

function PMenu({
  windowSize,
  progress,
  setShow,
  transitionsInner,
  progress2,
  texpProgress,
  show,
  playVideo,
  isMobile,
}) {
  const handleClose = useCallback(() => {
    setShow(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMeunItemY = useMemo(() => {
    const gap = 60;

    const lastY = 44;
    const startY = 112 + 32 + 17;

    // return menuList.map((l, i) => {
    //   return windowSize.height - ((menuList.length - 1 - i) * gap + lastY);
    // });

    return menuList.map((l, i) => {
      return i * gap + startY;
    });
  }, []);

  const playVideos = useMemo(() => {
    return menuList.map((l, i) => () => {
      console.log(`play ${i}`);
      playVideo(l.video);
    });
  }, [playVideo]);

  return (
    <>
      <PBlackMask
        windowSize={windowSize}
        progress={progress}
        color={0x2f2f2f}
        from="top"
        interactive={true}
      />

      <ContainerA alpha={texpProgress.to([0, 0.5, 1], [0, 1, 1])}>
        <PBtnX
          x={
            isMobile ? windowSize.width - 43 - 10 : windowSize.width - 149 - 10
          }
          y={isMobile ? 42 + 10 : 104 - 10}
          pointertap={handleClose}
        />

        {show &&
          menuList.map((l, i) => (
            <Text
              text={l.menuTitle}
              style={titleStyle}
              y={getMeunItemY[i]}
              x={43}
              anchor={[0, 1]}
              isSprite={true}
              interactive={true}
              pointertap={playVideos[i]}
            />
          ))}

        <ContainerA alpha={texpProgress.to([0, 0.5, 1], [0, 1, 1])}>
          <Text
            x={43}
            y={windowSize.height - 26 - 19 * 2}
            text="© 台灣愛惠浦股份有限公司版權所有。"
            style={copyRightTextStyle}
          ></Text>
        </ContainerA>
      </ContainerA>
    </>
  );
}

export const PMenuMobileA = ({
  windowSize,
  show,
  setShow,
  playVideo,
  isMobile,
  setMounted,
  menuMaskSpringApi,
}) => {
  const transitionsRef = useSpringRef();

  const [transitions] = useTransition(
    show,
    {
      from: { progress: 0, progress2: 0 },
      enter: (item) => async (next, cancel) => {
        if (item === false) {
          cancel();
          return;
        }

        menuMaskSpringApi.start({ progress: 1 });
        await next({ progress: 1 });

        setMounted?.(true);

        await next({ progress2: 1, config: config.molasses });
      },
      leave: (item) => async (next, cancel) => {
        if (item === false) {
          cancel();
          return;
        }
        setMounted?.(false);
        await next({ progress2: 0, immediate: true });

        menuMaskSpringApi.start({ progress: 0 });
        await next({ progress: 0 });
      },
      reverse: show,
      immediate: show ? ["progress2"] : ["progress2"],
      config: {},
      ref: transitionsRef,
    },
    [show]
  );

  const innetStyleSpringRef = useSpringRef();
  const [innetStyle] = useSpring(
    {
      innerProgress: show ? 1 : 0,
      ref: innetStyleSpringRef,
      config: config.molasses,
      // immediate: show ? [] : ["innerProgress"],
      reverse: !show,
    },
    [show]
  );
  useChain(
    show
      ? [transitionsRef, innetStyleSpringRef]
      : [innetStyleSpringRef, transitionsRef],
    show ? [0, 0.5] : [0, 0]
  );

  return transitions((styles, item) => {
    return (
      item && (
        <PMenu
          windowSize={windowSize}
          progress={styles.progress}
          setShow={setShow}
          progress2={styles.progress2}
          show={show}
          texpProgress={innetStyle.innerProgress}
          playVideo={playVideo}
          isMobile={isMobile}
          // transitionsInner={transitionsInner}
        />
      )
    );
  });
};

export default PMenuMobileA;
