// import { Text, Container } from "@inlet/react-pixi";
import { Text } from "@inlet/react-pixi";
import { Container as ContainerA, Graphics } from "@inlet/react-pixi/animated";
import { TextStyle } from "@pixi/text";

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { animated, useSpring } from "@react-spring/web";
import { MouseContext } from "../contexts/mouseContext";
import { to } from "react-spring";

// const Rectangle = PixiComponent("Rectangle", {
//   create: () => new PIXI.Graphics(),
//   applyProps: (g, _, props) => {
//     const { fill, x, y, width, height } = props;

//     g.clear();
//     g.beginFill(fill);
//     g.drawRect(x, y, width, height);
//     g.endFill();
//   },
// });

function PTitle({
  x,
  y,
  showSize,
  opacity = 1,
  title,
  subTitle,
  interactive,
  isMovingRef,
  windowSize,
  pointertap,
  hashTag,
}) {
  const [isHover, setIsHover] = useState(false);

  const styleSmall = useMemo(
    () =>
      new TextStyle({
        fontSize: 20 * (windowSize.width / 1920),
        fill: ["#ffffff"], // gradient
        fontFamily: "Noto Sans TC",
        fontWeight: 300,
      }),
    [windowSize.width]
  );
  const bigFontSize = 115;
  const styleBig = useMemo(
    () =>
      new TextStyle({
        fontFamily: "Noto Sans TC",
        fontSize: bigFontSize * (windowSize.width / 1920),
        fill: "#ffffff",
        color: "#ffffff",
        align: "left",
        fontWeight: 500,
        letterSpacing: -0.05 * 16 * (windowSize.width / 1920),
        lineHeight: 215,
      }),
    [windowSize.width]
  );

  const styleHashTagText = useMemo(
    () =>
      new TextStyle({
        fontFamily: "Noto Sans TC",
        fontSize: 29 * (windowSize.width / 1920),
        fill: "#ffffff",
        color: "#ffffff",
        align: "left",
        fontWeight: 300,
        lineHeight: 43,
      }),
    [windowSize.width]
  );

  const styleHashTag = useMemo(
    () =>
      new TextStyle({
        fontFamily: "Noto Sans TC",
        fontSize: 20 * (windowSize.width / 1920),
        fill: "#ffffff",
        color: "#ffffff",
        align: "left",
        fontWeight: 300,
        lineHeight: 43,
      }),
    [windowSize.width]
  );

  const [style, api] = useSpring(() => ({
    scale: 1,
  }));

  const { setType } = useContext(MouseContext);
  const handlePointerOver = useCallback(() => {
    setType("progress");
    api.start({
      to: {
        scale: 1.1,
      },
    });
    setIsHover(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePointerOut = useCallback(() => {
    setType("default");
    api.start({
      to: {
        scale: 1,
      },
    });
    setIsHover(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleClick = useCallback(() => {
  //   setType("play");
  //   setShowMask((p) => 1);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const containerRef = useRef();
  const bigTextRef = useRef();
  const smallTextRef = useRef();
  const gapBetewwnText = 20 * (windowSize.width / 1920);
  useEffect(() => {
    const width = (windowSize.width / 1920) * 500;

    containerRef.current.pivot.x = width / 2;
    containerRef.current.pivot.y =
      ((bigFontSize - 30) * (windowSize.width / 1920)) / 2;
  }, [windowSize.width]);

  const draw = useCallback(
    (g) => {
      g.clear();
      g.lineStyle(1, 0xffffff, 1);
      g.lineTo((windowSize.width / 1920) * 85, 0);
    },
    [windowSize.width]
  );

  const [progressStyle] = useSpring(
    {
      progress: isHover ? 1 : 0,
    },
    [isHover]
  );

  return (
    <ContainerA
      x={x}
      y={y}
      anchor={0.5}
      scale={style.scale}
      ref={containerRef}
      alpha={to(
        [opacity, progressStyle.progress.to([0, 1], [1, 0.5])],
        (a, b) => a * b
      )}
      interactive={interactive}
      buttonMode={true}
      pointerover={handlePointerOver}
      pointerout={handlePointerOut}
      pointertap={pointertap}
    >
      <Text
        text={subTitle}
        style={styleSmall}
        ref={smallTextRef}
        isSprite={false}
      />
      <Text
        ref={bigTextRef}
        text={title}
        y={gapBetewwnText}
        style={styleBig}
        isSprite={false}
      />

      <Text
        ref={bigTextRef}
        text={"#"}
        y={gapBetewwnText + 5}
        style={styleHashTag}
        x={(windowSize.width / 1920) * 455}
        isSprite={false}
      />

      <Text
        ref={bigTextRef}
        text={hashTag}
        y={gapBetewwnText + 5}
        style={styleHashTagText}
        x={(windowSize.width / 1920) * 468}
        isSprite={false}
      />

      <Graphics
        draw={draw}
        y={gapBetewwnText + 5 + (windowSize.width / 1920) * 36}
        x={(windowSize.width / 1920) * 468}
      />
      {/* <PixiMultiStyleText
          ref={bigTextRef}
          y={0}
          text={`${title}`}
          styles={styles}
        /> */}
    </ContainerA>
  );
}
export default animated(PTitle);
