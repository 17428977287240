import { PixiComponent } from "@inlet/react-pixi/animated";
import { animated } from "@react-spring/web";
import gsap, { Expo } from "gsap/all";

import PIXI from "../PIXI";
import { Camera3d, Sprite3d } from "pixi-projection";

const persistOX = [];
const persistOX2 = [];

function easeOutExpo(x: number): number {
  return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
}

// function easeOutCirc(x: number): number {
//   return Math.sqrt(1 - Math.pow(x - 1, 2));
// }
export default animated(
  PixiComponent("Pixi3dLayers", {
    create: ({
      data,
      imagePrefix,
      windowSize,
      cameraLimit,
      cameraLayerSize,
    }) => {
      const camera = new Camera3d();

      camera.position.set(
        cameraLayerSize?.width || 736.38,
        cameraLayerSize?.height || 414.214
      );
      camera.setPlanes(1000, 50, 5000, false);

      const scale = 1;
      const imageScale = 1720 / 1920;
      data
        .sort((a, b) => {
          if (typeof a.order !== "undefined") {
            return a.order - b.order;
          } else {
            return a.Position_z - b.Position_z;
          }
        })
        .forEach((s, i) => {
          const layer = new Sprite3d(
            PIXI.Texture.from(`${imagePrefix}/${s.file}`)
          );
          layer.anchor.set(0.5, 0.5);
          layer.position3d.z = s.Position_z * -1;
          layer.position3d.x = s.Position_x * 1 || 0;
          layer.position3d.y = s.Position_y * -1 || 0;

          layer.scale3d.x = s.Scale_x * scale * imageScale;
          layer.scale3d.y = s.Scale_y * scale * imageScale;
          layer.scale3d.z = s.Scale_z * scale * imageScale;

          if (s.Rotation_x) {
            layer.euler.x = (s.Rotation_x / 180) * Math.PI * -1;
          }
          if (s.Rotation_y) {
            layer.euler.y = (s.Rotation_y / 180) * Math.PI * -1;
          }
          if (s.Rotation_z) {
            layer.euler.z = (s.Rotation_z / 180) * Math.PI * -1;
          }

          camera.addChild(layer);
        });

      camera.interactive = true;

      camera.on("pointertap", (e) => {
        const pos = e.data.getLocalPosition(camera);
        // console.log(width / 2 - pos.x);
        // camera.position.x = width / 2 - pos.x;

        const trans = easeOutExpo;

        const directionX = pos.x > 0 ? 1 : -1;

        const directionY = pos.y > 0 ? -1 : 1;
        console.log({
          pos,
          y: (((Math.PI / 180) * pos.x) / 100) * 1,
          x: (((Math.PI / 180) * pos.y) / 100) * 1,
          tx:
            trans(Math.abs(pos.x) / (windowSize.width / 2)) *
            (directionX === 1 ? cameraLimit.maxX : cameraLimit.minX),
          ty:
            trans(Math.abs(pos.y) / (windowSize.height / 2)) *
            (directionY === 1 ? cameraLimit.maxY : cameraLimit.minY),
          ease: Expo.easeOut,
          duration: 4,
        });
        // camera.euler.y = (((Math.PI / 180) * pos.x) / 100) * 1;
        // camera.euler.x = (((Math.PI / 180) * pos.y) / 100) * 1;
      });
      camera.on("pointermove", (e) => {
        const pos = e.data.getLocalPosition(camera);
        // console.log(width / 2 - pos.x);
        // camera.position.x = width / 2 - pos.x;

        if (camera.gsap) {
          camera.gsap.kill();
        }

        const trans = easeOutExpo;

        const directionX = pos.x > 0 ? 1 : -1;

        const targetX =
          trans(Math.abs(pos.x) / (windowSize.width / 2)) *
          (directionX === 1 ? cameraLimit.maxX : cameraLimit.minX);

        const directionY = pos.y > 0 ? -1 : 1;

        const targetY =
          trans(Math.abs(pos.y) / (windowSize.height / 2)) *
          (directionY === 1 ? cameraLimit.maxY : cameraLimit.minY);

        camera.gsap = gsap.to(camera.euler, {
          y: targetX * (Math.PI / 360),
          x: targetY * (Math.PI / 360),
          ease: Expo.easeOut,
          duration: 4,
        });
        // gsap.to(camera.position3d, {
        //   y: pos.y * 0.3,
        //   x: pos.x * 0.3,
        //   ease: Expo.easeOut,
        //   duration: 4,
        // });
        // camera.euler.y = (((Math.PI / 180) * pos.x) / 100) * 1;
        // camera.euler.x = (((Math.PI / 180) * pos.y) / 100) * 1;
      });

      camera.tiltUp = () => {
        const painSize = -500;
        gsap.to(camera.euler, {
          x: (((Math.PI / 180) * painSize) / 100) * 1,
          ease: Expo.easeOut,
          duration: 4,
        });
      };

      camera.tiltDown = () => {
        const painSize = 500;
        gsap.to(camera.euler, {
          x: (((Math.PI / 180) * painSize) / 100) * 1,
          ease: Expo.easeOut,
          duration: 4,
        });
      };

      return camera;
    },
    applyProps: (instance, oldProps, newProps) => {
      const active = newProps.active;
      if (!instance.activeLock) {
        instance.interactive = active;
      }

      if (newProps.tiltActive && !oldProps.tiltActive) {
        if (newProps.progress < 0.5) {
          persistOX[newProps.imagePrefix] = instance.euler.x;
        }

        instance.activeLock = true;
        instance.interactive = false;

        if (instance.gsap) {
          instance.gsap.kill();
        }
      }
      if (!newProps.tiltActive && oldProps.tiltActive) {
        instance.activeLock = false;
        instance.interactive = active;
      }

      const painSize = 15;

      if (newProps.progress && newProps.progress !== oldProps.progress) {
        instance.euler.x =
          (persistOX[newProps.imagePrefix] ?? 0) +
          (Math.PI / 180) * painSize * newProps.progress;
      }

      //-------------

      if (newProps.tiltActive2 && !oldProps.tiltActive2) {
        if (newProps.progress2 < 0.5) {
          persistOX2[newProps.imagePrefix] = instance.euler.x;
        }

        instance.activeLock = true;
        instance.interactive = false;

        if (instance.gsap) {
          instance.gsap.kill();
        }
      }
      if (!newProps.tiltActive2 && oldProps.tiltActive2) {
        instance.interactive = active;
        instance.activeLock = false;
      }

      const painSize2 = -20;

      if (newProps.progress2 && newProps.progress2 !== oldProps.progress2) {
        instance.euler.x =
          (persistOX2[newProps.imagePrefix] ?? 0) +
          (Math.PI / 180) * painSize2 * newProps.progress2;
      }

      if (typeof newProps.cameraX !== "undefined") {
        instance.position3d.x = newProps.cameraX;
      }
    },
  })
);
