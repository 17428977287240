import { Graphics } from "@inlet/react-pixi/animated";
import { animated } from "@react-spring/web";
import React, { useCallback } from "react";

function PBlackMask({
  windowSize,
  progress,
  color = 0x000000,
  from = "bottom",
}) {
  const draw = useCallback(
    (g) => {
      const slope = 150;

      g.clear();
      g.beginFill(color);

      if (from === "bottom") {
        const dy = (1 - progress) * (windowSize.height + slope);

        const leftY = dy;
        const rightTopY = dy - slope;

        g.moveTo(0, leftY);

        g.lineTo(windowSize.width, rightTopY);

        g.lineTo(windowSize.width, windowSize.height + slope);

        g.lineTo(0, windowSize.height + slope);

        g.lineTo(0, leftY);

        g.endFill();
      } else {
        const dy = progress * (windowSize.height + slope);

        g.moveTo(0, 0);
        g.lineTo(windowSize.width, -slope);
        g.lineTo(windowSize.width, -slope + dy);
        g.lineTo(0, dy);
        g.lineTo(0, 0);
        g.endFill();
      }
    },
    [progress, windowSize.height, windowSize.width, color, from]
  );

  if (progress <= 0) {
    return null;
  }
  return <Graphics draw={draw}></Graphics>;
}

export default animated(PBlackMask);
