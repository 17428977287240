import {
  config,
  useChain,
  useSpring,
  useSpringRef,
  useTransition,
} from "@react-spring/core";

export const useIntroAnimations = ({ isActive }) => {
  const introUIStylesRef = useSpringRef();
  const [introUIStyles] = useSpring(
    {
      progress: isActive ? 1 : 0,
      config: config.molasses,
      ref: introUIStylesRef,
    },
    [isActive]
  );

  const introTitleStylesRef = useSpringRef();
  const [introTitleStyles] = useSpring(
    {
      progress: isActive ? 1 : 0,
      delay: 1500,
      config: config.molasses,
      ref: introTitleStylesRef,
    },
    [isActive]
  );

  const introTextSpringRef = useSpringRef();
  const [introTextSpring] = useSpring(
    {
      progress: isActive ? 1 : 0,
      config: {
        duration: 3500,
      },
    },
    [isActive]
  );
  useChain(
    [introTextSpringRef, introTitleStylesRef, introUIStylesRef],
    [0, 2.5, 6]
  );

  return { introTitleStyles, introUIStyles, introTextSpring };
};
