import { addGUIGroup } from "../components/datGUI";

export const configs = {
  rgbSplit: 2.76,
  deformation: 0.95,
};

addGUIGroup("effect", (gui2) => {
  gui2.add(configs, "rgbSplit", 0, 10, 0.1);
  gui2.add(configs, "deformation", 0, 10, 0.1);
});
