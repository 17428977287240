import { Container } from "@inlet/react-pixi/animated";

import { TextStyle } from "@pixi/text";

import React, { useMemo } from "react";

import { PBtnProgressText } from "./PBtnProgressText";

import PProgressLine from "./PProgressLine";

const paddingH = 150;
const gap = 7;
const contentHeight = 35 + 10;

const textStyle = new TextStyle({
  fontSize: 17,
  fill: 0xffffff,
  fontFamily: "Noto Sans TC",
  fontWeight: 400,
});

// const useProgressGroup = () => {
//   const [index, setIndex] = useState(0);

//   const [springs, api] = useSpring(() => ({
//     percentage: 0,
//     config: {
//       duration: 10000,
//     },
//   }));

//   useEffect(() => {
//     api.start({
//       to: async (next) => {
//         await next({
//           percentage: 1,
//         });
//         setTimeout(() => {
//           setIndex((i) => (i < videoList.length - 1 ? i + 1 : 0));
//         }, 200);
//       },
//       from: {
//         percentage: 0,
//       },
//     });
//   }, [api, index]);

//   return { springs, index };
// };

const paddingBottom = 64;

export default function PProgressGroup({
  windowSize,
  videoList,
  page,
  runningFullProgress,
  containerYProgress,
  jump,
}) {
  const y = windowSize.height - paddingBottom - contentHeight;
  const width =
    (windowSize.width - 2 * paddingH - (videoList.length - 1) * gap) /
    videoList.length;

  const getX = (i) => {
    if (i === 0) {
      return paddingH;
    }
    return paddingH + i * (width + gap);
  };

  const containerY = containerYProgress.to(
    (p) => p * 1 * (paddingBottom + contentHeight)
  );

  const handleJumps = useMemo(
    () =>
      videoList.map((_, i) => {
        return () => jump(i);
      }),
    [jump, videoList]
  );

  /*
  const hitArea = useMemo(() => {
    const g = new PIXI.Rectangle(0, 0, width, 36);

    return g;
  }, [width]);



  const { ovrideMouseStyle } = useContext(MouseContext);
  const handleMouseOver = useCallback(() => {
    ovrideMouseStyle({
      size: 75 / 2,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleMouseOut = useCallback(() => {
    ovrideMouseStyle(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  */

  return (
    <Container y={containerY}>
      {videoList.map((d, i) => (
        <Container
          key={`${i}`}
          // interactive={true}
          // buttonMode={true}
          // hitArea={hitArea}
          // pointertap={handleJumps[i]}
          // pointerover={handleMouseOver}
          // pointerout={handleMouseOut}
        >
          <PBtnProgressText
            opacity={1}
            x={getX(i) + 41}
            y={y + 10}
            text={`episode ${i + 1}`}
            subText={`${d.subTitle}＃${d.hashTag}`}
            subTextX={getX(i) + 1}
            subTextY={y + 5 + 20}
            textStyle={textStyle}
            pointertap={handleJumps[i]}
          />

          <PProgressLine
            x={getX(i)}
            y={y + 35 + 20}
            size={width}
            percentage={runningFullProgress.to((p) =>
              p > i && p <= i + 1 ? p - i : 0
            )}
          />
        </Container>
      ))}
    </Container>
  );
}
