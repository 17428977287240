import { Container, Graphics } from "@inlet/react-pixi";
import { animated } from "@react-spring/web";
import React, { useCallback, useContext, useMemo } from "react";
import { MouseContext } from "../contexts/mouseContext";
import PIXI from "../PIXI";
import { attachd } from "./MouseAttach";

const drawX = (g) => {
  g.clear();
  g.lineStyle(1, 0xffffff, 1);
  g.moveTo(-10, -10);
  g.lineTo(10, 10);
  g.moveTo(10, -10);
  g.lineTo(-10, 10);
};
const PXInner = animated(
  ({
    progress = 0,
    direction = 1,
    pointerover,
    pointerout,
    x,
    y,
    attachX,
    attachY,
    attached,
    pointertap,
    opacity = 1,
    ...rest
  }) => {
    const hitArea = useMemo(() => {
      const g = new PIXI.Rectangle(-20, -20, 40, 40);

      return g;
    }, []);
    return (
      <Container
        pointerover={pointerover}
        pointerout={pointerout}
        interactive={true}
        buttonMode={true}
        hitArea={hitArea}
        x={x}
        y={y}
        pointertap={pointertap}
        alpha={opacity}
      >
        <Graphics x={attachX - x} y={attachY - y} draw={drawX} />
      </Container>
    );
  }
);

export const PBtnX = attachd(
  React.memo(({ pointertap, ...props }) => {
    const { ovrideMouseStyle, mouseBreatheRef } = useContext(MouseContext);
    const handleMouseOver = useCallback(() => {
      ovrideMouseStyle({
        size: 30,
      });
      props.attach(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleMouseOut = useCallback(() => {
      ovrideMouseStyle(null);
      props.attach(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePointerTap = useCallback(() => {
      mouseBreatheRef.current?.(0.8);
      pointertap?.();
    }, [mouseBreatheRef, pointertap]);

    return (
      <PXInner
        {...props}
        pointertap={handlePointerTap}
        pointerover={handleMouseOver}
        pointerout={handleMouseOut}
      />
    );
  })
);
