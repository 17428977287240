import { Container, Graphics, Text } from "@inlet/react-pixi";
import { Container as ContainerA } from "@inlet/react-pixi/animated";
import { TextStyle } from "@pixi/text";
import { animated, useSpring } from "@react-spring/web";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { MouseContext } from "../contexts/mouseContext";
import PIXI from "../PIXI";
import { attachd } from "./MouseAttach";

const textStyle = new TextStyle({
  fontFamily: "Noto Sans TC",
  fill: 0xffffff,
  fontSize: 17,
});
const PBtnAllVideoInner = animated(
  ({
    progress = 0,
    direction = 1,
    pointerover,
    pointerout,
    x,
    y,
    attachX,
    attachY,
    attached,
    pointertap,
    opacity,
    ...rest
  }) => {
    const draw = useCallback((g) => {
      g.clear();
      g.beginFill(0xffffff, 1);
      g.drawCircle(0, 0, 5);
      g.drawCircle(-20, 0, 5);
      g.drawCircle(20, 0, 5);
      g.endFill();
    }, []);

    const hitArea = useMemo(() => {
      const addLeft = 100;
      const g = new PIXI.Rectangle(-32 - addLeft, -32, 96 + addLeft, 64);

      return g;
    }, []);

    return (
      <Container
        pointerover={pointerover}
        pointerout={pointerout}
        interactive={true}
        buttonMode={true}
        hitArea={hitArea}
        x={x}
        y={y}
        pointertap={pointertap}
        alpha={opacity}
      >
        <ContainerA alpha={1 - progress}>
          <Text
            text={"All Videos"}
            style={textStyle}
            x={-85 - 35 - 5 + (attachX - x)}
            y={-10 + attachY - y}
            isSprite={true}
          />
        </ContainerA>
        <Graphics x={attachX - x} y={attachY - y} draw={draw} />
      </Container>
    );
  }
);
export const PBtnAllVideo = attachd(
  React.memo(({ pointertap, isMobile, opacity, ...props }) => {
    const [isHover, setIsHover] = useState(false);

    const { ovrideMouseStyle, mouseBreatheRef } = useContext(MouseContext);
    const handleMouseOver = useCallback(() => {
      setIsHover(true);
      ovrideMouseStyle({
        size: 75 / 2,
      });
      props.attach(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleMouseOut = useCallback(() => {
      setIsHover(false);
      ovrideMouseStyle(null);
      props.attach(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [progressStyle] = useSpring(
      {
        progress: isHover ? 1 : 0,
      },
      [isHover]
    );

    const handlePointerTap = useCallback(() => {
      mouseBreatheRef.current?.(0.8, 150, () => {
        ovrideMouseStyle(null);
      });
      pointertap?.();
    }, [mouseBreatheRef, ovrideMouseStyle, pointertap]);

    return (
      <PBtnAllVideoInner
        {...props}
        opacity={opacity}
        pointertap={handlePointerTap}
        pointerover={handleMouseOver}
        pointerout={handleMouseOut}
        // interactive={true}
        // buttonMode={true}
        progress={isMobile ? 1 : progressStyle.progress}
      />
    );
  }),
  150,
  30
);
