import { Container, Graphics } from "@inlet/react-pixi/animated";

import { useSpring } from "@react-spring/core";
import { animated } from "@react-spring/web";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { MouseContext } from "../contexts/mouseContext";
import PPlayVideoProgress from "./PPlayVideoProgress";

const paddingX = 0;
const paddingY = 0;
const progressRadius = 123 / 2;

// const drawTriangle = (g) => {
//   g.clear();
//   g.beginFill("0xaf0a0a");
//   g.moveTo(0, 0);
//   g.lineTo(0, 23);
//   g.lineTo(20, 23 / 2);
//   g.endFill();
// };

const drawTriangleW = (g) => {
  g.clear();
  g.beginFill(0xffffff);
  g.moveTo(0, 0);
  g.lineTo(0, 23);
  g.lineTo(20, 23 / 2);
  g.endFill();
};

// const platVideoTextStyle = new TextStyle({
//   // align: "left",
//   // fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
//   fontSize: 17,
//   // fontWeight: 400,
//   fill: ["#ffffff"], // gradient
// });
const InnerCircle = animated(({ size = 15, fill = false }) => {
  const drawCircle = useCallback(
    (g) => {
      g.clear();
      if (fill) {
        g.beginFill(0xffffff);
      }

      g.lineStyle(1, 0xffffff, 1);

      g.drawCircle(paddingX, paddingY, size);
      if (fill) {
        g.endFill();
      }
    },
    [size, fill]
  );
  return <Graphics preventRedraw={true} draw={drawCircle}></Graphics>;
});

// const ProgressCircle = animated(
//   ({ progress = 1, size = 15, color = 0xaf0a0a }) => {
//     const draw = useCallback(
//       (g) => {
//         var radius = size;
//         var endPercent = 87;
//         // var curPerc = 0;
//         // var counterClockwise = false;
//         var circ = Math.PI * 2;
//         var quart = Math.PI - 0.4;

//         g.clear();
//         g.lineStyle(1, color, 1);

//         // g.beginFill(0xff3300);
//         // g.beginPath();
//         g.arc(
//           paddingX,
//           paddingY,
//           radius,
//           -quart,
//           (circ * progress * endPercent) / 100 - quart,
//           false
//         );
//         // g.endFill();
//         // g.stroke();
//       },
//       [progress, size, color]
//     );

//     return <Graphics preventRedraw={true} draw={draw} />;
//   }
// );

const AnimateShowOrNot = animated(({ show, children, whenNotShow = null }) => {
  return show ? children : whenNotShow;
});

const defaultSize = 15;

export default function Mouse({ runningProgress, opacity }) {
  const { type, extraMouseStyle, mouseSpringRef, mouseBreatheRef } =
    useContext(MouseContext);

  const extraMouseStyleRef = useRef(extraMouseStyle);
  extraMouseStyleRef.current = extraMouseStyle;

  const [style, api] = useSpring(() => ({
    x: 0,
    y: 0,
    config: {
      mass: 5,
      friction: 40,
      tension: 150,
    },
  }));
  mouseSpringRef.current = style;

  const [styleScale, apiScale] = useSpring(() => ({
    size: defaultSize,
    showPlay: false,
    showPlaying: false,
    showPausing: false,
    fill: false,

    config: {
      mass: 1,
      friction: 5,
      tension: 100,
      duration: 400,
    },
  }));

  const handleMouseMove = useCallback(
    (e) => {
      if (extraMouseStyleRef.current?.lock) {
        return;
      }
      api.start({
        to: {
          x: e.data.global.x,
          y: e.data.global.y,
        },
      });
    },
    [api]
  );

  useEffect(() => {
    if (extraMouseStyle?.lock) {
      api.start({
        to: {
          x: extraMouseStyle?.x,
          y: extraMouseStyle?.y,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraMouseStyle?.lock]);

  const handleMouseBreathe = useCallback(
    (rate = 0.5, duration = 150, cb) => {
      apiScale.start({
        to: async (next, cancel) => {
          const currentSize = extraMouseStyle
            ? extraMouseStyle.size
            : defaultSize;
          await next({
            size: currentSize * rate,
            config: {
              duration,
            },
          });
          await next({
            size: currentSize,
            config: {
              duration,
            },
          });
          cb?.();
        },
      });
    },
    [apiScale, extraMouseStyle]
  );
  mouseBreatheRef.current = handleMouseBreathe;

  useEffect(() => {
    if (extraMouseStyle) {
      const size = extraMouseStyle.size;

      if (size) {
        apiScale.start({
          to: {
            size: size,
          },
        });

        return () => {
          apiScale.start({
            to: {
              size: defaultSize,
            },
          });
        };
      }
    }
  }, [api, apiScale, extraMouseStyle]);

  useEffect(() => {
    if (type === "progress") {
      apiScale.start({
        to: async (next, cancel) => {
          await next({ size: progressRadius });
          await next({ showPlay: true });
          // setShowPlay(true);
        },
      });
    }
    if (type === "default") {
      // setShowPlay(false);

      apiScale.start({
        to: async (next, cancel) => {
          await next({
            showPlay: false,
            showPlaying: false,
            showPausing: false,
            fill: false,
          });
          await next({
            size: 15,
          });
        },
      });
    }

    if (type === "play") {
      apiScale.start({
        to: async (next, cancel) => {
          await next({
            size: progressRadius - 15,
            config: {
              mass: 1,
              friction: 5,
              tension: 100,
              duration: 200,
            },
          });
          await next({
            size: progressRadius,
            config: {
              mass: 1,
              friction: 5,
              tension: 100,
              duration: 200,
            },
          });
          await next({
            size: 15,
            showPlay: false,
          });
        },
      });
    }

    if (type === "pausing") {
      apiScale.start({
        to: async (next, cancel) => {
          // await next({
          //   size: 61.5,
          // });
          await next({
            showPausing: true,
            showPlaying: false,
            fill: false,
          });
        },
      });
    }

    if (type === "playing") {
      apiScale.start({
        to: async (next, cancel) => {
          // await next({
          //   size: 61.5,
          // });
          await next({
            showPlaying: true,
            showPausing: false,
            fill: false,
          });
        },
      });
    }

    if (type === "control") {
      apiScale.start({
        to: async (next, cancel) => {
          await next({
            size: 5,
          });
          await next({
            showPlaying: false,
            showPausing: false,
            fill: true,
          });
        },
      });
    }
  }, [apiScale, type]);
  const drawPause = useCallback((g) => {
    g.beginFill(0xffffff);
    g.drawRect(-4 - 3, -10, 6, 20);
    g.drawRect(4 + 3, -10, 6, 20);
    g.endFill();
  }, []);

  return (
    <Container
      interactive={true}
      pointermove={handleMouseMove}
      interactiveChildren={false}
      alpha={opacity}
      // mouseup={handleMouseClick}
      x={style.x}
      y={style.y}
    >
      {type !== "progress" && (
        <InnerCircle size={styleScale.size} fill={styleScale.fill} />
      )}
      {/*  */}

      <AnimateShowOrNot show={styleScale.showPlaying}>
        <Graphics draw={drawPause}></Graphics>
      </AnimateShowOrNot>
      <AnimateShowOrNot show={styleScale.showPausing}>
        <Graphics
          x={-7 + paddingX}
          y={-11 + paddingY}
          draw={drawTriangleW}
        ></Graphics>
      </AnimateShowOrNot>
      {/* {styleScale.showPlaying && <Graphics draw={drawPause}></Graphics>}
      {styleScale.showPlaying && <Graphics draw={drawPause}></Graphics>} */}
      {/* {type === "progress" || type === "play" ? (
        <ProgressCircle size={styleScale.size} color={0xffffff} progress={1} />
      ) : (
        <InnerCircle size={styleScale.size} />
      )} */}

      <PPlayVideoProgress
        showProgress={type === "progress"}
        runningProgress={runningProgress}
        paddingX={paddingX}
        paddingY={paddingY}
        size={styleScale.size}
        showPlay={styleScale.showPlay}
      />
      {/* {type === "progress" && (
        <PProgressCircle
          size={styleScale.size}
          color={0xaf0a0a}
          progress={runningProgress}
          paddingX={paddingX}
          paddingY={paddingY}
        />
      )}
      <AnimateShowOrNot show={styleScale.showPlay}>
        <React.Fragment>
          <Graphics x={-7 + paddingX} y={-11 + paddingY} draw={drawTriangle} />
          <Text
            text={"Play Video"}
            style={platVideoTextStyle}
            x={paddingX - 103}
            y={paddingY - 10}
          />
        </React.Fragment>
      </AnimateShowOrNot> */}
    </Container>
  );
}
