import { PixiComponent } from "@inlet/react-pixi/animated";
import PIXI from "../PIXI";
import MultiStyleText from "pixi-multistyle-text";
import gsap from "gsap/gsap-core";
import { Expo } from "gsap/gsap-core";
import { Camera3d } from "pixi-projection";

const Pixi3dLoading = PixiComponent("Pixi3dLoading", {
  create: ({ width, height }) => {
    const camera = new Camera3d();

    camera.position.set(width / 2, height / 2);
    camera.setPlanes(1000, 50, 5000, false);

    // const text3d = new PIXI.projection.Text3d("AAA", style);

    var container = new PIXI.Container();
    let text = new MultiStyleText("01", {
      default: {
        fontFamily: "Antonio",
        fontWeight: 500,
        fontSize: 110,
        align: "center",
        fill: 0xffffff,
      },
    });
    text.anchor.x = 0.5;
    text.anchor.y = 0.5;
    // console.log(text);
    container.addChild(text);
    container.convertTo3d();
    container.position3d.set(0, 0, 0);

    var container2 = new PIXI.Container();
    var graphics = new PIXI.Graphics();

    // graphics.beginFill(0xFFFF00);
    graphics.lineStyle(1, 0x454545);
    const w = 180;
    const h = 150;
    graphics.drawRect(-w / 2, -h / 2, w, h);

    container2.addChild(graphics);
    container2.convertTo3d();

    container2.position3d.set(0, 30, 30);

    camera.addChild(container2);

    camera.addChild(container);

    camera.interactive = true;

    camera.on("pointertap", (e) => {
      console.log("pointertap");
    });
    camera.on("pointermove", (e) => {
      const pos = e.data.global;
      const cX = pos.x - width / 2;
      const cY = pos.y - height / 2;
      const pX = cX / (width / 2);
      const pY = cY / (height / 2);
      gsap.to(camera.euler, {
        y: ((pX * Math.PI) / 3) * -1,
        x: (pY * Math.PI) / 3,
        ease: Expo.easeOut,
        duration: 4,
      });
      // camera.euler.y = ((pX * Math.PI) / 4) * -1;
      // camera.euler.x = (pY * Math.PI) / 4;
      // console.log(pX);

      const dx = Math.abs(cX);
      const dy = Math.abs(cY);
      const max = Math.sqrt(width * width + height * height);

      const length = Math.sqrt(dx * dx + dy * dy);

      const p = length / max;
      container.scale.x = 1.3 - p * 0.3;
      container.scale.y = 1.3 - p * 0.3;

      // console.log(pX, pY);
    });

    camera.textInstance = text;
    return camera;
  },
  applyProps: (instance, oldProps, newProps) => {
    const newProgress = Math.round(newProps.progress);

    instance.textInstance.text =
      newProgress > 10 ? `${newProgress}` : `0${newProgress}`;

    // instance.interactive = active;
  },
});

export default Pixi3dLoading;
