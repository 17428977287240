import React, { createRef, useRef, useState } from "react";

const defaultMouseContextValue = {
  type: "default",
  setType: () => {},
  showMask: false,
  setShowMask: () => {},
  extraMouseStyle: null,
  ovrideMouseStyle: () => {},
  mouseSpringRef: createRef(),
  mouseBreatheRef: createRef(),
  menuMaskSpring: null,
  setMenuMaskSpring: () => {},
};
export const MouseContext = React.createContext(defaultMouseContextValue);

export const MouseContextProvider = ({ children }) => {
  const [type, setType] = useState("default");
  const [showMask, setShowMask] = useState(0);
  const [extraMouseStyle, ovrideMouseStyle] = useState(null);
  const mouseSpringRef = useRef();
  const mouseBreatheRef = useRef();

  const [menuMaskSpring, setMenuMaskSpring] = useState(null);

  return (
    <MouseContext.Provider
      value={{
        type,
        setType,
        showMask,
        setShowMask,
        ovrideMouseStyle,
        extraMouseStyle,

        mouseSpringRef,
        mouseBreatheRef,

        menuMaskSpring,
        setMenuMaskSpring,
      }}
    >
      {children}
    </MouseContext.Provider>
  );
};
