/* eslint-disable jsx-a11y/alt-text */
import {
  Container,
  Stage as PixiStage,
  useApp,
} from "@inlet/react-pixi/animated";
import { useUpdateEffect, useWindowSize } from "react-use";

import { useCallback, useContext, useEffect, useRef, useState } from "react";

import PBox from "./components/PBox";
import P3Groups from "./components/P3Groups";
import PTitle from "./components/PTitle";
import Mouse from "./components/Mouse";
import PIXI from "./PIXI";
import Pixi3dLayer from "./components/Pixi3dLayer";
import { videoList } from "./data/videoList";
import PProgressGroup from "./components/PProgressGroup";
import { useVideoListControl } from "./hooks/useVideoListControl";
import PBlackMask from "./components/PBlackMask";
import { MouseContext } from "./contexts/mouseContext";
import PVideoPlayer from "./components/PVideoPlayer";

import { PBtnArrow } from "./components/PBtnArrow";
import { PBtnAllVideo } from "./components/PBtnAllVideo";
import { PMenuA } from "./components/PMenu";
import PLoadingNumber from "./components/PLoadingNumber";
import { Sprite } from "@inlet/react-pixi/animated";
import { useLoader } from "./hooks/useLoader";
import { useIntroAnimations } from "./hooks/useIntroAnimations";
import { to, useSpring } from "@react-spring/core";
import PTitleMobile from "./components/PTitleMobile";
import PPlayVideoProgress from "./components/PPlayVideoProgress";
import { useRWD } from "./hooks/useRWD";
import PProgressGroupMobile from "./components/PProgressGroupMobile";
import PIntroText from "./components/PIntroText";

import PMenuMobileA from "./components/PMenuMobile";
import { PBtnLogo } from "./components/PBtnLogo";

// import ReactPlayer from "react-player";
// import screenfull from "screenfull";
// import { findDOMNode } from "react-dom";
import "video.js/dist/video-js.css";
import { useVideoJS } from "react-hook-videojs";
import { PBtnInnovation } from "./components/PBtnInnovation";

const gap = 0;
const pages = videoList.length;

const ScrollControl = ({ page, setPage, isMoving, active }) => {
  const app = useApp();
  const indexRef = useRef(page);
  indexRef.current = page;
  const activeRef = useRef();
  activeRef.current = active;

  const isLockRef = useRef(false);

  useEffect(() => {
    const mousePosition = new PIXI.Point();

    const handelMouseWheel = (ev) => {
      ev.preventDefault();
      if (!activeRef.current) {
        return;
      }
      mousePosition.set(ev.clientX, ev.clientY); // get global position

      if (isLockRef.current) {
        return;
      }

      const lock = () => {
        isLockRef.current = true;
        setTimeout(() => {
          isLockRef.current = false;
        }, 500);
      };
      // returns element directly under mouse
      if (ev.deltaX > 10) {
        const currentIndex = indexRef.current;

        setPage(currentIndex + 1, 1);
        lock();
      }
      if (ev.deltaX < -10) {
        const currentIndex = indexRef.current;

        setPage(currentIndex - 1, -1);
        lock();
      }
    };
    app.view.addEventListener("mousewheel", handelMouseWheel);

    app.renderer.mask.enableScissor = false;
    return () => {
      app?.view?.removeEventListener?.("mousewheel", handelMouseWheel);
    };
  }, []);

  return null;
};

// const useGetScrollIndex = (scrollRef, width, setPage) => {
//   const [index, setIndex] = useState(0);
//   const indexRef = useRef(0);
//   indexRef.current = index;
//   useEffect(() => {
//     const scrollHandler = (e) => {
//       const x = scrollRef.current?.scrollLeft ?? 0;
//       const currentIndex = indexRef.current;
//       const newIndex = x === 0 ? 0 : Math.round(x / width);

//       if (newIndex !== currentIndex) {
//         setIndex(newIndex);
//         setPage(newIndex, newIndex - currentIndex);
//       }
//     };

//     scrollRef.current.addEventListener("scroll", scrollHandler, {
//       capture: false,
//       passive: true,
//     });

//     return () => {
//       // eslint-disable-next-line react-hooks/exhaustive-deps
//       scrollRef.current?.removeEventListener("scroll", scrollHandler);
//     };
//   }, [scrollRef, setPage, width]);

//   return { index };
// };

const ContextBridge = ({ children, Context, render }) => {
  return (
    <Context.Consumer>
      {(value) =>
        render(<Context.Provider value={value}>{children}</Context.Provider>)
      }
    </Context.Consumer>
  );
};

const Stage = ({ children, ...props }) => {
  return (
    <ContextBridge
      Context={MouseContext}
      render={(children) => <PixiStage {...props}>{children}</PixiStage>}
    >
      {children}
    </ContextBridge>
  );
};

// const GetMouseType = ({ children }) => {
//   const ctx = useContext(MouseContext);

//   return children(ctx);
// };

const useMobileGestrue = ({ handleNextPage, handlePrevPage, isMobile }) => {
  const dataRef = useRef({
    state: "idle",
    startX: 0,
  });

  const handleDown = useCallback((e) => {
    dataRef.current = { state: "down", startX: e.data.global.x };
  }, []);

  const handleMove = useCallback(
    (e) => {
      const range = 40;
      const data = dataRef.current;
      if (data.state === "down") {
        if (e.data.global.x > data.startX + range) {
          dataRef.current = { state: "react", startX: 0 };
          if (isMobile) {
            handlePrevPage();
          }
        }
        if (e.data.global.x < data.startX - range) {
          dataRef.current = { state: "react", startX: 0 };
          if (isMobile) {
            handleNextPage();
          }
        }
      }
    },
    [handleNextPage, handlePrevPage, isMobile]
  );

  const handleUp = useCallback((e) => {
    dataRef.current = { state: "idle", startX: 0 };
  }, []);
  return { handleDown, handleMove, handleUp };
};

const MVideo = ({
  videoUrl,
  windowSize,
  playerRef,
  handleClose,
  startTime,
  callback,
}) => {
  // const height = (windowSize.width / 1920) * 720;
  // const style = {
  //   width: windowSize.width,
  //   height: height,
  //   top: (windowSize.height - height) / 2,
  // };'=
  const height = windowSize.height;
  const style = {
    width: windowSize.width,
    height: height,
    top: 0,
  };

  const [showX, setShowX] = useState(false);
  const { Video, player, ready } = useVideoJS({
    sources: [{ src: videoUrl }],
    controls: false,
    // playsinline: true,
    autoPlay: true,
  });
  playerRef.current = player;

  useUpdateEffect(() => {
    if (player) {
      player.requestFullscreen();
      let jounped = false;
      player.on("canplay", () => {
        if (!jounped) {
          player.currentTime(startTime);
          jounped = true;
          if (!player.isFullscreen()) {
            player.pause();
            // player.setControls(false);
            player.controls(false);
            setShowX(true);
          }
        }
      });
      player.on("fullscreenchange", function (e) {
        if (player.isFullscreen()) {
          console.log("fullscreen");
          player.controls(true);
        } else {
          console.log("exit fullscreen");
          callback(player.currentTime());
          handleClose();
        }
      });
    }
  }, [player]);

  return (
    <>
      <Video
        style={{
          width: windowSize.width,
          height: style.height,
        }}
        autoPlay={true}
      />
      {/* {showX && (
        <img
          src={"icons/close.png"}
          style={{ position: "absolute", top: 30, right: 90 }}
          onClick={() => {
            callback(player.currentTime());
            handleClose();
          }}
        />
      )} */}
      {showX && (
        <div
          onClick={() => {
            player.requestFullscreen();
            player.play();
          }}
          style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
        >
          <img
            src={"icons/Button.png"}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: `translate(-50%, -50%)`,
            }}
          />
        </div>
      )}
    </>
  );
};

const useMobileVideo = ({ windowSize }) => {
  const [{ videoUrl, from, callback }, setState] = useState({
    videoUrl: "",
    from: 0,
    callback: null,
  });

  const playerRef = useRef();
  const handleOpen = useCallback(
    (url, from, callback) => setState({ videoUrl: url, from, callback }),
    []
  );
  const handleClose = useCallback(() => setState({ videoUrl: "" }), []);

  const height = (windowSize.width / 1920) * 720;
  const style = {
    width: windowSize.width,
    height: windowSize.height,
    top: 0,
  };

  return {
    videoUrl,
    from,
    handleOpen,
    handleClose,
    style,
    playerRef,
    callback,
  };
};

function App() {
  const windowSize = useWindowSize();
  const [showAllVideos, setShowVideos] = useState(false);

  const { isLoading, progress } = useLoader();

  const {
    page,
    direction,
    renderAll,
    springs: styles,
    titlesProps,
    runningProgress,
    runningFullProgress,
    maskSpring,
    titleFadeSpring,
    playVideo,
    handleExitVideo,
    handleNextPage,
    handlePrevPage,
    isMoving,
    setPlayVideo,
    handleJumpPage,
    setPage,
  } = useVideoListControl({
    windowSize,
    pages,
    pause: showAllVideos || isLoading,
    isLoading,
    gap,
  });
  const { setShowMask, setType } = useContext(MouseContext);

  const handlePlayVideoFromMenu = useCallback((video) => {
    setShowMask(2);
    setPlayVideo(video);
    setTimeout(() => {
      setShowVideos(false);
      titleFadeSpring.progress.set(1);
    }, 500);
  }, []);

  const handlePressLogo = useCallback(() => {
    setShowVideos(false);
    setPlayVideo(false);
    setShowMask(0);
  }, []);

  const { introUIStyles, introTitleStyles, introTextSpring } =
    useIntroAnimations({
      isActive: !isLoading,
    });

  const { isMobile } = useRWD();
  const ProgressGroup = isMobile ? PProgressGroupMobile : PProgressGroup;
  const [isAllVideoMaskMounted, setIsAllVideoMaskMounted] = useState(false);

  const [menuMaskSpring, menuMaskSpringApi] = useSpring(() => ({
    progress: 0,
  }));

  const [UIState, setUIState] = useState("active");
  const [UIStyle] = useSpring(
    {
      opacity: UIState === "active" ? 1 : 0,
    },
    [UIState]
  );

  const rate = Math.min(windowSize.width / 1920, 1);

  const g = useMobileGestrue({
    handleNextPage,
    handlePrevPage,
    isMobile,
  });

  const mobileVideoControl = useMobileVideo({ windowSize });

  return (
    <>
      <Stage
        width={windowSize.width}
        height={windowSize.height}
        options={{
          antialias: true,
          autoDensity: true,
          backgroundColor: 0x000000,
          autoResize: false,
          resolution: window.devicePixelRatio || 1,
        }}
      >
        {!isLoading && (
          <>
            {!((playVideo && !isMobile) || isAllVideoMaskMounted) && (
              <>
                <ScrollControl
                  page={page}
                  setPage={setPage}
                  active={!showAllVideos && !playVideo && !isMoving}
                />
                <Container
                  interactive={true}
                  pointerdown={g.handleDown}
                  pointerup={g.handleUp}
                  pointermove={g.handleMove}
                >
                  <P3Groups
                    x={styles.x}
                    m={styles.progress.to(
                      [0, 0.1, 0.5, 0.9, 1],
                      [0, 0, isMobile ? 0.15 : 0.5, 0, 0]
                    )}
                    d={styles.direction}
                  >
                    {videoList
                      .map((d, i) => ({ ...d, i: i }))
                      .reverse()
                      .map((data) => {
                        const i = data.i;
                        return page === i ||
                          i === page + direction * -1 ||
                          renderAll ? (
                          <PBox
                            x={i * windowSize.width + i * gap}
                            width={windowSize.width}
                            height={windowSize.height}
                            innerWidth={data.cameraLayerSize.width * 2}
                            innerHeight={data.cameraLayerSize.height * 2}
                            type={"cover"}
                            key={i}
                          >
                            <Pixi3dLayer
                              active={page === i && !showAllVideos}
                              tiltActive={
                                page === i &&
                                to([maskSpring.progress], () => {
                                  return maskSpring.progress.isAnimating;
                                })
                              }
                              progress={page === i && maskSpring.progress}
                              tiltActive2={
                                page === i &&
                                to([menuMaskSpring.progress], () => {
                                  return menuMaskSpring.progress.isAnimating;
                                })
                              }
                              progress2={page === i && menuMaskSpring.progress}
                              data={data.photo3d}
                              windowSize={windowSize}
                              cameraLayerSize={data.cameraLayerSize}
                              cameraLimit={data.cameraLimit}
                              imagePrefix={data.imagePrefix}
                              cameraX={styles.progress.to((p) => {
                                const max = windowSize.width / 2;
                                const isCenter = page === i;
                                const isPrev = page - direction === i;
                                if (isPrev) {
                                  const v = p;
                                  return (max - v * max) * direction * 1;
                                }
                                if (isCenter) {
                                  const v = 1 - p;
                                  return (max - v * max) * direction * -1;
                                }
                              })}
                            />
                          </PBox>
                        ) : null;
                      })
                      .filter((d) => d)}
                  </P3Groups>
                </Container>
                {!isMobile && (
                  <PBtnArrow
                    x={introUIStyles.progress.to([0, 1], [-5, 149 * rate])}
                    y={windowSize.height / 2}
                    direction={-1}
                    pointertap={handlePrevPage}
                  />
                )}

                {!isMobile && (
                  <PBtnArrow
                    x={introUIStyles.progress.to(
                      [0, 1],
                      [windowSize.width + 5, windowSize.width - 149 * rate]
                    )}
                    y={windowSize.height / 2}
                    direction={1}
                    pointertap={handleNextPage}
                  />
                )}
                {/* {!isMobile && (
                  <PBtnInnovation
                    width={75}
                    height={75}
                    source={"best-innovation-white.png"}
                    x={windowSize.width - 45 - 75 + 25}
                    y={windowSize.height - 45 - 75 + 25}
                    pointertap={() => {
                      window.open(
                        "https://www.cssdesignawards.com/sites/everpure-brand-video/39983/",
                        "_blank"
                      );
                    }}
                  />
                )} */}
              </>
            )}
            <PBlackMask
              windowSize={windowSize}
              progress={maskSpring.progress}
              color={0x000000}
              from="bottom"
            />
            {!isAllVideoMaskMounted && (
              <Container
                x={introTitleStyles.progress.to([0, 1], [windowSize.width, 0])}
              >
                {titlesProps.map(({ x, opacity }, i) => {
                  if (isMobile) {
                    return (
                      <PTitleMobile
                        key={`t_${i}`}
                        x={x.to((rx) => rx - windowSize.width / 2 + 43)}
                        y={windowSize.height - 210}
                        opacity={to(
                          [opacity, titleFadeSpring.progress.to((p) => 1 - p)],
                          (a, b) => a * b
                        )}
                        title={videoList[i].subTitle}
                        subTitle={videoList[i].menuTitle}
                        hashTag={videoList[i].hashTag}
                        interactive={!isMoving && !showAllVideos}
                        pointertap={() => {
                          setType("play");
                          setShowMask((p) => 1);
                          setPlayVideo(videoList[i].video);
                        }}
                      >
                        <Container x={videoList[i].mobilePlayX} y={57}>
                          <PPlayVideoProgress
                            isMobile={isMobile}
                            showPlay={true}
                            showProgress={true}
                            runningProgress={runningFullProgress.to((p) =>
                              p > i && p <= i + 1 ? p - i : 0
                            )}
                            size={25}
                            showProgressBack={true}
                          />
                        </Container>
                      </PTitleMobile>
                    );
                  }
                  return (
                    <PTitle
                      key={`t_${i}`}
                      x={x}
                      y={windowSize.height / 2}
                      opacity={to(
                        [opacity, titleFadeSpring.progress.to((p) => 1 - p)],
                        (a, b) => a * b
                      )}
                      title={videoList[i].subTitle}
                      subTitle={videoList[i].menuTitle}
                      hashTag={videoList[i].hashTag}
                      interactive={!isMoving && !showAllVideos}
                      windowSize={windowSize}
                      pointertap={() => {
                        setType("play");
                        setShowMask((p) => 1);
                      }}
                      // key={windowSize.width}
                      // interactive={false}
                    />
                  );
                })}
              </Container>
            )}

            {!isAllVideoMaskMounted && (
              <ProgressGroup
                windowSize={windowSize}
                videoList={videoList}
                page={page}
                runningFullProgress={runningFullProgress}
                containerYProgress={to(
                  [
                    titleFadeSpring.progress.to([0, 0.3, 1], [0, 1, 1]),
                    introUIStyles.progress.to([0, 1], [1, 0]),
                  ],
                  (a, b) => {
                    // default 0, out 1
                    return a + b;
                  }
                )}
                jump={handleJumpPage}
              />
            )}

            {playVideo &&
              (isMobile ? (
                <Container
                  alpha={maskSpring.progress.to([0, 0.99, 1], [0, 0, 1])}
                >
                  <PVideoPlayer
                    windowSize={windowSize}
                    onExit={handleExitVideo}
                    video={playVideo}
                    isMobile={isMobile}
                    isAllVideoMaskMounted={isAllVideoMaskMounted}
                    showAllVideos={showAllVideos}
                    setUIState={setUIState}
                    UIStyle={UIStyle}
                    UIState={UIState}
                    rate={rate}
                    mobileVideoControl={mobileVideoControl}
                  />
                </Container>
              ) : (
                <PVideoPlayer
                  windowSize={windowSize}
                  onExit={handleExitVideo}
                  video={playVideo}
                  isMobile={isMobile}
                  isAllVideoMaskMounted={isAllVideoMaskMounted}
                  showAllVideos={showAllVideos}
                  setUIState={setUIState}
                  UIStyle={UIStyle}
                  UIState={UIState}
                  rate={rate}
                  mobileVideoControl={mobileVideoControl}
                />
              ))}
            {!showAllVideos && (
              <PBtnAllVideo
                opacity={UIStyle.opacity}
                x={
                  isMobile
                    ? windowSize.width - 43 - 25
                    : windowSize.width - 169 * rate - 5
                }
                y={
                  isMobile
                    ? introUIStyles.progress.to([0, 1], [-15, 38 + 15])
                    : introUIStyles.progress.to([0, 1], [-15, 110 * rate + 5])
                }
                isMobile={isMobile}
                pointertap={() => {
                  setShowVideos((p) => !p);
                }}
              />
            )}

            {isMobile ? (
              <PMenuMobileA
                windowSize={windowSize}
                show={showAllVideos}
                setShow={setShowVideos}
                playVideo={handlePlayVideoFromMenu}
                isMobile={isMobile}
                setMounted={setIsAllVideoMaskMounted}
                menuMaskSpringApi={menuMaskSpringApi}
              />
            ) : (
              <PMenuA
                windowSize={windowSize}
                show={showAllVideos}
                setShow={setShowVideos}
                playVideo={handlePlayVideoFromMenu}
                isMobile={isMobile}
                setMounted={setIsAllVideoMaskMounted}
                menuMaskSpringApi={menuMaskSpringApi}
                rate={rate}
              />
            )}
          </>
        )}
        {isLoading && (
          <PLoadingNumber
            width={windowSize.width}
            height={windowSize.height}
            progress={progress}
          />
        )}

        <PIntroText
          windowSize={windowSize}
          progress={introTextSpring.progress}
          isMobile={isMobile}
        />

        {isMobile ? (
          <Sprite
            x={43}
            y={40}
            width={91}
            height={28 * (91 / 113)}
            source={"logo.png"}
            interactive={true}
            pointertap={handlePressLogo}
            buttonMode={true}
            alpha={UIStyle.opacity}
          />
        ) : (
          <PBtnLogo
            x={149 * rate + 113 / 2}
            y={100 * rate + 28 / 2}
            width={113}
            height={28}
            pointertap={handlePressLogo}
            alpha={UIStyle.opacity}
          />
        )}

        {!isMobile && (
          <Mouse opacity={UIStyle.opacity} runningProgress={runningProgress} />
        )}
      </Stage>
      {
        <div
          className="mobile-video"
          style={{
            position: "absolute",
            top: mobileVideoControl.style.top,
            height: mobileVideoControl.style.height,
            left: 0,
            right: 0,
            background: "red",
            display: mobileVideoControl.videoUrl ? "block" : "none",
          }}
        >
          {mobileVideoControl.videoUrl && (
            <MVideo
              videoUrl={mobileVideoControl.videoUrl}
              windowSize={windowSize}
              playerRef={mobileVideoControl.playerRef}
              handleClose={mobileVideoControl.handleClose}
              startTime={mobileVideoControl.from}
              callback={mobileVideoControl.callback}
            />
          )}
        </div>
      }
    </>
  );
}

export default App;
