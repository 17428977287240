import * as dat from "dat.gui";
const urlParams = new URLSearchParams(window.location.search);
const config = urlParams.get("config");

let gui;
if (config) {
  gui = new dat.GUI();
  gui.closed = true;
}

export const addGUIGroup = (name, func) => {
  if (config) {
    var folder = gui.addFolder(name);
    func(folder);
  }
};
