import { useSpring } from "@react-spring/core";
import { animated } from "@react-spring/web";
import React, { useContext, useState } from "react";
import { MouseContext } from "../contexts/mouseContext";

export const attachd = (Comp, ax = 30, ay = 30) =>
  animated(({ x, y, ...props }) => {
    const [isAttach, setIsAttach] = useState(false);
    const { mouseSpringRef } = useContext(MouseContext);

    const [attachStyle] = useSpring(
      {
        x: isAttach
          ? mouseSpringRef?.current?.x.to((mx) =>
              mx > x ? Math.min(x + ax, mx) : Math.max(x - ax, mx)
            )
          : x,
        y: isAttach
          ? mouseSpringRef?.current?.y.to((my) =>
              my > y ? Math.min(y + ay, my) : Math.max(y - ay, my)
            )
          : y,
        config: { duration: 200 },
      },
      [isAttach, x, y]
    );

    return (
      <Comp
        {...props}
        x={x}
        y={y}
        attachX={attachStyle.x}
        attachY={attachStyle.y}
        attach={setIsAttach}
        attachd={isAttach}
      />
    );
  });
