import { Container, Text } from "@inlet/react-pixi";
import {
  Sprite,
  useApp,
  Container as ContainerA,
  Graphics,
} from "@inlet/react-pixi/animated";

import { TextStyle } from "@pixi/text";
import { useSpring } from "@react-spring/core";
import gsap from "gsap/gsap-core";

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { usePrevious, useUpdateEffect } from "react-use";
import { MouseContext } from "../contexts/mouseContext";

import PBox from "./PBox";
import { PBtnSound } from "./PBtnSound";
import { PBtnX } from "./PBtnX";
import PixiSlider from "./PixiSlider";

const textStyle = new TextStyle({
  fill: 0xffffff,
  fontSize: 17,
  align: "center",
});

const textStyleMobile = new TextStyle({
  fill: 0xffffff,
  fontSize: 13,
  align: "center",
});

function PVideoPlayer({
  windowSize,
  onExit,
  video,
  isMobile,
  isAllVideoMaskMounted,
  showAllVideos,
  setUIState,
  UIStyle,
  UIState,
  rate,
  mobileVideoControl,
}) {
  const videoRef = useRef();
  const sliderRef = useRef();
  const textRef = useRef();
  const app = useApp();

  const [videoState, setVideoState] = useState("loading");
  const [muted, setMuted] = useState(false);

  useEffect(() => {
    window.videoRef = videoRef.current;

    const source = videoRef.current.texture.baseTexture.resource.source;
    console.log(source);
    source.preload = "auto";
    // source.autoplay = true;
    window.videoSource = source;

    const getDisplayTime = (currentTime) => {
      let minutes = Math.floor(currentTime / 60);
      let seconds = Math.floor(currentTime - minutes * 60);
      let minuteValue;
      let secondValue;

      if (minutes < 10) {
        minuteValue = "0" + minutes;
      } else {
        minuteValue = minutes;
      }

      if (seconds < 10) {
        secondValue = "0" + seconds;
      } else {
        secondValue = seconds;
      }

      let mediaTime = minuteValue + ":" + secondValue;
      return mediaTime;
    };

    function onTimeupdate() {
      // console.log(mediaTime);
      // timer.textContent = mediaTime;

      // sliderRef.current.duration = source.duration;
      // sliderRef.current.currentTime = source.currentTime;

      if (!sliderRef.current.dragging) {
        textRef.current.text = getDisplayTime(source.currentTime);
        gsap.to(sliderRef.current, {
          progress: source.currentTime / source.duration,
          duration: 0.3,
        });
      }

      // sliderRef.current.progress(source.currentTime / source.duration);

      // let barLength = timerWrapper.clientWidth * (media.currentTime/media.duration);
      // timerBar.style.width = barLength + 'px';
    }
    source.addEventListener("timeupdate", onTimeupdate);

    sliderRef.current.drawCb = (p, dragging, progress) => {
      if (dragging) {
        textRef.current.text = getDisplayTime(progress * source.duration);
      }
      textRef.current.x = p;
    };

    // gsap.to(textRef.current, {
    //   x:
    //     (source.currentTime / source.duration) *
    //     sliderRef.current.props.width,
    //   duration: 0.3,
    // });
    const handelPlay = () => {
      setVideoState("play");
    };
    const handelPause = () => {
      setVideoState("pause");
    };
    const handleCanPlay = () => {
      console.log("handleCanPlay");
      source.play();
    };

    const handleEnded = () => {
      setUIState("active");
    };

    source.addEventListener("play", handelPlay);
    source.addEventListener("pause", handelPause);

    source.addEventListener("canplay", handleCanPlay);

    source.addEventListener("ended", handleEnded);

    source.currentTime = 0;
    source.play();

    setMuted(source.muted);
    return () => {
      source.currentTime = 0;
      source.pause();
      source.load();
      source.removeEventListener("timeupdate", onTimeupdate);
      source.removeEventListener("canplay", handleCanPlay);
      source.removeEventListener("ended", handleEnded);
    };
  }, [setUIState, video]);

  useEffect(() => {
    if (showAllVideos) {
      return;
    }
    let timer;
    let state = "active";

    const clearTimer = () => {
      if (typeof timer !== "undefined") {
        clearTimeout(timer);
      }
    };
    const handleMouseMove = (e) => {
      clearTimer();
      timer = setTimeout(() => {
        state = "idle";
        setUIState("idle");
        // app.view.style.cursor = "none";
      }, 2500);

      if (state !== "active") {
        state = "active";
        setUIState("active");
        // app.view.style.cursor = "auto";
      }
    };
    app.view.addEventListener("mousemove", handleMouseMove);
    app.view.addEventListener("mousedown", handleMouseMove);
    if (!isMobile) {
      handleMouseMove();
    }

    return () => {
      app.view?.removeEventListener?.("mousemove", handleMouseMove);
      app.view?.removeEventListener?.("mousedown", handleMouseMove);

      clearTimer();
    };
  }, [app.view, isMobile, setUIState, showAllVideos]);

  useUpdateEffect(() => {
    if (UIState === "idle" && videoState === "play") {
      app.view.style.cursor = "none";
    } else {
      if (app.view.style.cursor === "none") {
        app.view.style.cursor = "auto";
      }
    }

    return () => {
      if (app.view.style.cursor === "none") {
        app.view.style.cursor = "auto";
      }
    };
  }, [UIState]);

  const handleJump = ({ progress }) => {
    // console.log(progress);
    const source = videoRef.current.texture.baseTexture.resource.source;
    // console.log(Math.round(progress * source.duration));
    source.currentTime = Math.round(progress * source.duration);

    if (source.paused || source.ended) {
      source.play();
    }
  };

  const handleVideoTap = useCallback(() => {
    setTimeout(() => {
      mouseBreatheRef.current?.(0.8);
    }, 100);
    const source = videoRef.current.texture.baseTexture.resource.source;
    if (source.paused || source.ended) {
      source.play();
    } else {
      source.pause();
    }
  }, []);

  const sliderY = windowSize.height - 44 * rate - 36;
  const sliderPadding = isMobile ? 26 : 146 * rate;
  const sliderX = sliderPadding;
  const sliderWidth = windowSize.width - 2 * sliderPadding;

  const buttonXWidth = 20;
  const buttonXX = isMobile
    ? windowSize.width - buttonXWidth / 2 - 43
    : windowSize.width - sliderPadding - buttonXWidth / 2;
  const buttonXY = isMobile
    ? 95 + buttonXWidth / 2
    : 185 * rate + buttonXWidth / 2;

  const handleExit = useCallback(() => {
    const source = videoRef.current?.texture.baseTexture.resource.source;
    if (source) {
      source.pause();
    }
    onExit();
  }, [onExit]);

  const [isVideoHover, setIsVideoHover] = useState(false);

  const { setType, mouseBreatheRef, ovrideMouseStyle } =
    useContext(MouseContext);

  const videoHintAreaMouseOver = useCallback(() => {
    // setType("playing");
    setIsVideoHover(true);
  }, []);

  const videoHintAreaMouseOut = useCallback(() => {
    // setType("default");
    setIsVideoHover(false);
  }, []);

  // const handelClickVideoHintArea = useCallback(() => {
  //   setTimeout(() => {
  //     mouseBreatheRef.current?.(0.8);
  //   }, 100);

  //   const source = videoRef.current?.texture.baseTexture.resource.source;
  //   if (source) {
  //     if (videoState === "play") {
  //       source.pause();
  //     } else {
  //       source.play();
  //     }
  //   }
  // }, [mouseBreatheRef, videoState]);

  const handleMutePress = useCallback(() => {
    const source = videoRef.current?.texture.baseTexture.resource.source;

    source.muted = !source.muted;
    setMuted(source.muted);
  }, []);

  const handleFullScreen = useCallback(() => {
    const elem = videoRef.current?.texture.baseTexture.resource.source;
    console.log(elem);

    elem.pause();

    // mobileVideoControl.handleFullScreen();

    mobileVideoControl.handleOpen(video, elem.currentTime, (time) => {
      console.log(time);
      elem.currentTime = time;
    });
    // elem.webkitEnterFullscreen();
    // elem.enterFullscreen();
    // if (elem.enterFullscreen) {
    //   elem.enterFullscreen();
    //   console.log("  elem.enterFullscreen();");
    // } else if (elem.webkitEnterFullScreen) {
    //   elem.webkitEnterFullScreen();
    //   console.log("  elem.webkitEnterFullScreen();");
    // } else if (elem.requestFullscreen) {
    //   elem.requestFullscreen().catch((e) => {
    //     console.log(e);
    //   });
    //   console.log("  elem.requestFullscreen();");
    // } else if (elem.msRequestFullscreen) {
    //   elem.msRequestFullscreen();
    //   console.log("  elem.msRequestFullscreen();");
    // } else if (elem.mozRequestFullScreen) {
    //   elem.mozRequestFullScreen();
    //   console.log("  elem.mozRequestFullScreen();");
    // } else if (elem.webkitRequestFullscreen) {
    //   elem.webkitRequestFullscreen();
    //   console.log("  elem.webkitRequestFullscreen();");
    // }
  }, []);

  useEffect(() => {
    if (isVideoHover) {
      if (videoState === "play") {
        setType("playing");
      }
      if (videoState === "pause") {
        setType("pausing");
      }
    } else {
      setType("default");
    }
  }, [isVideoHover, setType, videoState]);

  useEffect(() => {
    if (isVideoHover) {
      ovrideMouseStyle({
        size: 61.5,
      });
    } else {
      ovrideMouseStyle(null);
    }
  }, [isVideoHover, ovrideMouseStyle]);

  const [playStyles] = useSpring(
    {
      opacity: videoState === "play" ? 1 : 0.7,
    },
    [videoState]
  );

  const prevShowAllVideos = usePrevious(showAllVideos);
  useEffect(() => {
    if (prevShowAllVideos === false && showAllVideos && videoState === "play") {
      const source = videoRef.current?.texture.baseTexture.resource.source;
      source.pause();
    }
  }, [prevShowAllVideos, showAllVideos, videoState]);

  const draawPlay = useCallback((g) => {
    g.clear();
    g.beginFill("0xffffff", 0.7);
    g.moveTo(0, 0);
    g.lineTo(0, 23);
    g.lineTo(20, 23 / 2);
    g.endFill();

    g.lineStyle(1, 0xffffff, 0.7);
    g.drawCircle(7, 23 / 2, 40);
  }, []);
  return (
    <>
      <PBox
        width={windowSize.width}
        y={137 * rate}
        height={windowSize.height - 137 * rate - 137 * rate}
        innerWidth={1920}
        innerHeight={720}
        paddingTop={isMobile ? 25 : 95 * rate}
        type={"contain"}
      >
        <Sprite
          key={video}
          ref={videoRef}
          x={0}
          y={0}
          alpha={playStyles.opacity}
          // video={'videos/sample-mp4-file.mp4'}
          video={video}
          interactive={!showAllVideos}
          pointertap={handleVideoTap}
          pointerover={videoHintAreaMouseOver}
          pointerout={videoHintAreaMouseOut}
          // buttonMode={!showAllVideos && !(UIState === "idle")}
          // buttonMode={!(showAllVideos || UIState === "idle")}
          // buttonMode={!showAllVideos}
          buttonMode={false}
        />
      </PBox>
      {isMobile && (
        <Graphics
          alpha={playStyles.opacity.to([0.7, 1], [1, 0])}
          draw={draawPlay}
          x={windowSize.width / 2 - 7}
          y={windowSize.height / 2 - 10}
        />
      )}

      {/* {!showAllVideos && (
        <PVideoHitArea
          windowSize={windowSize}
          pointerover={videoHintAreaMouseOver}
          pointerout={videoHintAreaMouseOut}
          pointertap={handelClickVideoHintArea}
        />
      )} */}

      <ContainerA alpha={UIStyle.opacity} priest>
        {!isAllVideoMaskMounted && (
          <>
            <PBtnX
              interactive={true}
              buttonMode={true}
              x={buttonXX}
              y={buttonXY}
              pointertap={handleExit}
            />

            <PBtnSound
              muted={muted}
              interactive={true}
              buttonMode={true}
              x={buttonXX - 49 - 25 / 2}
              y={buttonXY}
              pointertap={handleMutePress}
            />

            {isMobile && (
              <Sprite
                x={buttonXX - 49 - 25 / 2 - 49 - 25 / 2}
                y={buttonXY}
                image={"./icons/M_fullscreen_BT.png"}
                anchor={0.5}
                interactive={true}
                pointertap={handleFullScreen}
                buttonMode
              />
            )}
          </>
        )}

        <Container y={sliderY} x={sliderX}>
          <PixiSlider
            ref={sliderRef}
            width={sliderWidth}
            progress={0}
            onChange={handleJump}
            ovrideMouseStyle={ovrideMouseStyle}
            setType={setType}
          />
          <Container x={isMobile ? -17 : -21} y={10}>
            <Text
              ref={textRef}
              text={"0:00"}
              style={isMobile ? textStyleMobile : textStyle}
            />
          </Container>
        </Container>
      </ContainerA>
    </>
  );
}

export default React.memo(PVideoPlayer);
