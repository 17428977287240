import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Container } from "@inlet/react-pixi/animated";
import PIXI from "../PIXI";

export default function PBox({
  x = 0,
  y = 0,
  width,
  height,
  innerWidth,
  innerHeight,
  type,
  initCb,
  children,
  paddingTop = 0,
  debug,
}) {
  const [isInit, setIsInit] = useState(false);
  // const maskRef = useRef();
  const draw = useCallback(
    (g) => {
      g.clear();
      g.beginFill(0x000000);
      g.drawRect(0, 0, width, height);
      g.endFill();
      return g;
    },
    [height, width]
  );

  const innerConfig = useMemo(() => {
    const count = ({ innerWidth, innerHeight, width, height }) => {
      let winratio = width / height;
      const spratio = innerWidth / innerHeight;

      let scale = 1;
      let x = 0;
      let y = 0;

      if (type === "cover" ? winratio > spratio : winratio < spratio) {
        //photo is wider than background
        scale = width / innerWidth;
        y = -(innerHeight * scale - height) / 2;
      } else {
        //photo is taller than background
        scale = height / innerHeight;
        x = -(innerWidth * scale - width) / 2;
      }
      return {
        x: x,
        y: y,
        scale: scale,
      };
    };

    const { x, y, scale } = count({ innerWidth, innerHeight, width, height });

    if (type === "contain" && y < paddingTop) {
      const newRes = count({
        innerWidth,
        innerHeight,
        width,
        height: height - paddingTop,
      });

      return {
        x: newRes.x,
        y: newRes.y + paddingTop,
        scale: new PIXI.Point(newRes.scale, newRes.scale),
      };
    }

    return {
      x,
      y,
      scale: new PIXI.Point(scale, scale),
    };
  }, [width, height, innerWidth, innerHeight, paddingTop, type]);
  // useEffect(() => {
  //   setIsInit(true);
  //   if (initCb) {
  //     initCb();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const mask = useMemo(() => {
    const graphics = new PIXI.Graphics();

    return draw(graphics);
  }, [draw]);

  const containerRef = useRef();
  useEffect(() => {
    const m = mask;
    // m.rotation = 0.01;
    containerRef.current.addChild(m);

    setIsInit(true);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      containerRef?.current?.removeChild(m);
    };
  }, [mask]);

  // const drawMask = useCallback(() => mask, [mask]);

  return (
    <Container
      ref={containerRef}
      x={x}
      y={y}
      alpha={isInit ? 1 : 0}
      mask={mask}
    >
      {/* <Graphics preventRedraw={true} draw={draw} ref={maskRef} /> */}

      <Container
        x={innerConfig.x}
        y={innerConfig.y}
        scale={innerConfig.scale}
        // draw={drawMask}
      >
        {children}
      </Container>
      {/* <Graphics draw={draw} /> */}

      {/* {isInit && (
        <Container
          x={innerConfig.x}
          y={innerConfig.y}
          scale={innerConfig.scale}
          mask={maskRef.current}
        >
          {children}
        </Container>
      )} */}
    </Container>
  );
}
