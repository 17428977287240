import { Graphics, Text, Sprite, Container } from "@inlet/react-pixi";
import {
  // Sprite,
  useApp,
  Container as ContainerA,
} from "@inlet/react-pixi/animated";
import { TextStyle } from "@pixi/text";
import {
  config,
  to,
  useChain,
  useSpring,
  useSpringRef,
  useTransition,
} from "@react-spring/core";
import { animated } from "@react-spring/web";
import { clamp } from "gsap/gsap-core";
import React, { memo, useCallback, useEffect, useRef } from "react";

import { menuList } from "../data/videoList";
import PIXI from "../PIXI";

import P3GroupsF from "./P3GroupsF";
import PBlackMask from "./PBlackMask";
import { PBtnX } from "./PBtnX";

const titleStyle = new TextStyle({
  fill: 0xffffff,
  fontSize: 60,
  fontWeight: 200,
  align: "center",
  fontFamily: "Noto Sans TC",
});

const scrollTextStyle = new TextStyle({
  fill: 0xffffff,
  fontSize: 17,
  fontWeight: 300,
  align: "center",
  fontFamily: "Noto Sans TC",
});

const copyRightTextStyle = new TextStyle({
  fill: 0xffffff,
  fontSize: 13,
  lineHeight: 19,
  align: "left",
  fontFamily: "Noto Sans TC",
});

const itmWidth = 1064;
const minX = 149;

const ShowOnlyAnimationg = animated(({ trackingVal, children }) => {
  return trackingVal ? children : null;
});

const MSprite = memo(Sprite, () => true);

const videoList = menuList;

function PMenu({
  windowSize,
  progress,
  setShow,
  transitionsInner,
  progress2,
  texpProgress,
  show,
  playVideo,
  isMobile,
  rate,
}) {
  const app = useApp();

  const containerRef = useRef();
  const maxX =
    minX -
    (149 + videoList.length * itmWidth + (videoList.length - 1) * 85) +
    windowSize.width -
    149;

  const [menuListStyles, api] = useSpring(
    {
      x: 149,
    },
    []
  );

  const countRef = useRef(149);

  useEffect(() => {
    const mousePosition = new PIXI.Point();

    let isBusy = false;

    const handelMouseWheel = async (ev) => {
      ev.preventDefault();
      if (isBusy) {
        return;
      }

      isBusy = true;
      mousePosition.set(ev.clientX, ev.clientY); // get global position

      const deltaX = ev.deltaX;
      const deltaY = ev.deltaY;

      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        countRef.current = clamp(maxX, minX, countRef.current - deltaX);
        // containerRef.current.x = countRef.current;
        await api.start({ x: countRef.current });

        isBusy = false;
      } else {
        countRef.current = clamp(maxX, minX, countRef.current - deltaY);

        await api.start({ x: countRef.current });
        isBusy = false;
      }
    };

    app.view.addEventListener("mousewheel", handelMouseWheel);

    return () => {
      app?.view?.removeEventListener?.("mousewheel", handelMouseWheel);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const drawBlank = useCallback(
    (g) => {
      g.clear();
      g.beginFill(0x2f2f2f);
      g.drawRect(-windowSize.width, 0, windowSize.width + minX, 400);
      g.endFill();
    },
    [windowSize.width]
  );
  return (
    <>
      <PBlackMask
        windowSize={windowSize}
        progress={progress}
        color={0x2f2f2f}
        from="top"
        interactive={true}
      />
      {show && (
        <>
          <ContainerA
            y={(windowSize.height - 431) / 2}
            x={to([progress2, menuListStyles.x], (animateX, scrollX) => {
              if (show) {
                const length = windowSize.width - minX + 10;

                return (
                  clamp(maxX, minX, scrollX) + (length - animateX * length)
                );
              } else {
                return clamp(maxX, minX, scrollX);
              }
            })}
          >
            <Container ref={containerRef}>
              <P3GroupsF
                x={menuListStyles.x.to((x) => clamp(maxX, minX, x))}
                deltaXOverwride={
                  show &&
                  progress2.to([0, 0.5, 1], [0, 1, 0]).to((x) => x * 100 * 1)
                }
              >
                {show && (
                  <ShowOnlyAnimationg trackingVal={progress2}>
                    <Graphics draw={drawBlank} />
                  </ShowOnlyAnimationg>
                )}

                {videoList.map((v, index) => (
                  <MSprite
                    image={v.cover}
                    x={index * (1064 + 85)}
                    key={`${index}`}
                    interactive={true}
                    buttonMode={true}
                    pointertap={() => playVideo(v.video)}
                  />
                ))}
              </P3GroupsF>

              <ContainerA
                x={texpProgress.to((p) => {
                  const length = 1064 / 2;
                  return length - p * length;
                })}
              >
                {videoList.map((v, index) => (
                  <Text
                    x={index * (1064 + 85) + 1064 / 2}
                    y={342 + 50}
                    style={titleStyle}
                    text={v.menuTitle}
                    anchor={0.5}
                    isSprite={true}
                    key={`${index}`}
                  />
                ))}
              </ContainerA>
            </Container>
          </ContainerA>
        </>
      )}

      <PBtnX
        opacity={texpProgress.to([0, 0.5, 1], [0, 1, 1])}
        x={isMobile ? windowSize.width - 43 - 10 : windowSize.width - 149 - 10}
        y={isMobile ? 42 + 10 : 104 - 10}
        pointertap={handleClose}
      />

      <ContainerA
        alpha={texpProgress.to([0, 0.5, 1], [0, 1, 1])}
        y={texpProgress.to([0, 0.5, 1], [0, 1, 1]).to((x) => {
          const length = 50;
          return length - x * length;
        })}
      >
        <Text
          y={windowSize.height - 66 - 33 - 10 - 25 / 2}
          x={windowSize.width / 2}
          style={scrollTextStyle}
          text="scroll"
          anchor={0.5}
          isSprite={true}
        />
        <Sprite
          image={"icons/mouseScroll.png"}
          anchor={0.5}
          y={windowSize.height - 66 - 33 / 2}
          x={windowSize.width / 2}
        />
      </ContainerA>

      <ContainerA alpha={texpProgress.to([0, 0.5, 1], [0, 1, 1])}>
        <Text
          x={isMobile ? 43 : 149 * rate}
          y={windowSize.height - 40 - 19 * 2}
          text="© 台灣愛惠浦股份有限公司版權所有。"
          style={copyRightTextStyle}
        ></Text>
      </ContainerA>
    </>
  );
}

export const PMenuA = ({
  windowSize,
  show,
  setShow,
  playVideo,
  isMobile,
  setMounted,
  menuMaskSpringApi,
  rate,
}) => {
  const transitionsRef = useSpringRef();

  const [transitions] = useTransition(
    show,
    {
      from: { progress: 0, progress2: 0 },
      enter: (item) => async (next, cancel) => {
        if (item === false) {
          cancel();
          return;
        }

        menuMaskSpringApi.start({ progress: 1 });
        await next({ progress: 1 });

        setMounted?.(true);

        await next({ progress2: 1, config: config.molasses });
      },
      leave: (item) => async (next, cancel) => {
        if (item === false) {
          cancel();
          return;
        }
        setMounted?.(false);
        await next({ progress2: 0, immediate: true });

        menuMaskSpringApi.start({ progress: 0 });
        await next({ progress: 0 });
      },
      reverse: show,
      immediate: show ? ["progress2"] : ["progress2"],
      config: {},
      ref: transitionsRef,
    },
    [show]
  );

  const innetStyleSpringRef = useSpringRef();
  const [innetStyle] = useSpring(
    {
      innerProgress: show ? 1 : 0,
      ref: innetStyleSpringRef,
      config: config.molasses,
      // immediate: show ? [] : ["innerProgress"],
      reverse: !show,
    },
    [show]
  );
  useChain(
    show
      ? [transitionsRef, innetStyleSpringRef]
      : [innetStyleSpringRef, transitionsRef],
    show ? [0, 1] : [0, 0]
  );

  return transitions((styles, item) => {
    return (
      item && (
        <PMenu
          windowSize={windowSize}
          progress={styles.progress}
          setShow={setShow}
          progress2={styles.progress2}
          show={show}
          texpProgress={innetStyle.innerProgress}
          playVideo={playVideo}
          isMobile={isMobile}
          rate={rate}
          // transitionsInner={transitionsInner}
        />
      )
    );
  });
};

export default PMenu;
