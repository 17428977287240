import { Text } from "@inlet/react-pixi";
import { Container, Graphics } from "@inlet/react-pixi/animated";
import { TextStyle } from "@pixi/text";
import { animated } from "@react-spring/web";
import React, { useMemo } from "react";
import PIXI from "../PIXI";
import PProgressCircle from "./PProgressCircle";

const platVideoTextStyle = new TextStyle({
  // align: "left",
  // fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
  fontSize: 17,
  // fontWeight: 400,
  fill: ["#ffffff"], // gradient
});

const platVideoTextStyleMobile = new TextStyle({
  // align: "left",
  // fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
  fontSize: 13,
  // fontWeight: 400,
  fill: ["#ffffff"], // gradient
});

function PPlayVideoProgress({
  showProgress,
  runningProgress,
  paddingX = 0,
  paddingY = 0,
  size,
  showPlay,
  isMobile,
  showProgressBack,
  opacity = 1,
}) {
  const drawTriangle = (g) => {
    g.clear();
    g.beginFill("0xaf0a0a", opacity);
    g.moveTo(0, 0);
    g.lineTo(0, 23);
    g.lineTo(20, 23 / 2);
    g.endFill();
  };

  const drawTriangleMobile = (g) => {
    g.clear();
    g.beginFill("0xaf0a0a", opacity);
    g.moveTo(0, 0);
    g.lineTo(0, 15);
    g.lineTo(12, 15 / 2);
    g.endFill();
  };

  const hitArea = useMemo(() => {
    const g = new PIXI.Rectangle(paddingX - 80, paddingY - 8 - 20, 110, 60);

    return g;
  }, [paddingX, paddingY]);
  return (
    <>
      {/* {showProgressBack && (
        <PProgressCircle
          size={size}
          color={0xffffff}
          progress={1}
          paddingY={paddingY}
          paddingX={paddingX}
        />
      )} */}
      <Container hitArea={isMobile ? hitArea : undefined}>
        {/* <Graphics draw={drawRange} /> */}
        {showProgress && (
          <PProgressCircle
            size={size}
            color={0xaf0a0a}
            progress={runningProgress}
            paddingY={paddingY}
            paddingX={paddingX}
            opacity={opacity}
          />
        )}
        {showPlay && (
          <React.Fragment>
            <Graphics
              x={-5 + paddingX}
              y={-8 + paddingY}
              draw={isMobile ? drawTriangleMobile : drawTriangle}
            />
            <Text
              text={"Play Video"}
              style={isMobile ? platVideoTextStyleMobile : platVideoTextStyle}
              x={isMobile ? paddingX - 80 : paddingX - 103}
              y={isMobile ? paddingY - 8 : paddingY - 10}
              isSprite={true}
            />
          </React.Fragment>
        )}
      </Container>
    </>
  );
}

export default animated(PPlayVideoProgress);
