import { Text } from "@inlet/react-pixi";
import { Container } from "@inlet/react-pixi/animated";
import { TextStyle } from "@pixi/text";
import { useSpring, useSprings } from "@react-spring/core";

import React from "react";

import PProgressLine from "./PProgressLine";

const paddingH = 43;

const gap = 7;
const contentHeight = 35;

const textStyle = new TextStyle({
  fontSize: 17,
  fill: 0xffffff,
  fontFamily: "Noto Sans TC",
  fontWeight: 400,
});

const paddingBottom = 64;

export default function PProgressGroupMobile({
  windowSize,
  videoList,
  page,
  runningFullProgress,
  containerYProgress,
}) {
  const y = windowSize.height - paddingBottom - contentHeight;
  const width = ((windowSize.width - paddingH) * 2) / 3;

  const getX = (i) => {
    if (i === 0) {
      return paddingH;
    }
    return paddingH + i * (width + gap);
  };

  const containerY = containerYProgress.to(
    (p) => p * 1 * (paddingBottom + contentHeight)
  );

  const [aStyles] = useSpring(
    {
      x: page * (width + gap) * -1,
    },
    [page]
  );

  const [springs] = useSprings(
    videoList.length,
    videoList.map((item, index) => ({ opacity: index >= page ? 1 : 0 })),
    [page]
  );
  return (
    <Container y={containerY} x={aStyles.x}>
      {springs.map((a, i) => (
        <Container alpha={a.opacity} key={`${i}`} x={getX(i)} y={y}>
          <Text text={`episode ${i + 1}`} style={textStyle} isSprite={true} />
          <PProgressLine
            y={35}
            size={width}
            percentage={runningFullProgress.to((p) =>
              p > i && p <= i + 1 ? p - i : 0
            )}
          />
        </Container>
      ))}
    </Container>
  );
}
