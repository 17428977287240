import { Container, Graphics } from "@inlet/react-pixi";
import { animated, useSpring } from "@react-spring/web";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { MouseContext } from "../contexts/mouseContext";
import PIXI from "../PIXI";
import { attachd } from "./MouseAttach";

const PArrowInner = animated(
  ({
    progress = 0,
    direction = 1,
    pointerover,
    pointerout,
    x,
    y,
    attachX,
    attachY,
    attached,
    pointertap,
    ...rest
  }) => {
    const draw = useCallback(
      (g) => {
        const height = 32;
        const paddingY = -height / 2;
        g.clear();
        g.lineStyle(1, 0xffffff, 1);
        g.moveTo(0 - progress * 3 * direction, 0 + progress * 5 + paddingY);
        g.lineTo(0 + progress * 9 * direction, height / 2 + paddingY);
        g.lineTo(
          0 - progress * 3 * direction,
          height - progress * 5 + paddingY
        );
      },
      [direction, progress]
    );

    const hitArea = useMemo(() => {
      // const origionalSize = 32;
      // const paddingY = -origionalSize / 2;
      const targetSize = 250;
      // const extraSize = (targetSize - origionalSize) / 2;
      const g = new PIXI.Rectangle(
        -targetSize / 2,
        -targetSize / 2,
        targetSize,
        targetSize
      );

      return g;
    }, []);

    return (
      <Container
        pointerover={pointerover}
        pointerout={pointerout}
        interactive={true}
        buttonMode={true}
        hitArea={hitArea}
        x={x}
        y={y}
        pointertap={pointertap}
      >
        <Graphics x={attachX - x} y={attachY - y} draw={draw} />
      </Container>
    );
  }
);

export const PBtnArrow = attachd(
  React.memo(({ pointertap, ...props }) => {
    const [isHover, setIsHover] = useState(false);

    const { ovrideMouseStyle, mouseBreatheRef } = useContext(MouseContext);
    const handleMouseOver = useCallback(() => {
      setIsHover(true);
      ovrideMouseStyle({
        size: 61.5,
      });
      props.attach(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleMouseOut = useCallback(() => {
      setIsHover(false);
      ovrideMouseStyle(null);
      props.attach(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [progressStyle] = useSpring(
      {
        progress: isHover ? 1 : 0,
      },
      [isHover]
    );
    const handlePointerTap = useCallback(() => {
      mouseBreatheRef.current?.(0.8);
      pointertap?.();
    }, [mouseBreatheRef, pointertap]);

    return (
      <PArrowInner
        {...props}
        pointertap={handlePointerTap}
        pointerover={handleMouseOver}
        pointerout={handleMouseOut}
        // interactive={true}
        // buttonMode={true}
        progress={progressStyle.progress}
      />
    );
  }),
  125,
  125
);
