import { useEffect, useState } from "react";

const query = "(min-width: 1440px)";
const mql = window.matchMedia(query);

const getInitState = () => {
  return mql.matches;
};

export const useRWD = () => {
  const [state, setState] = useState(getInitState);

  useEffect(() => {
    let mounted = true;

    const onChange = () => {
      if (!mounted) {
        return;
      }
      setState(!!mql.matches);
    };

    mql.addListener(onChange);
    setState(mql.matches);

    return () => {
      mounted = false;
      mql.removeListener(onChange);
    };
  }, []);

  return {
    isMobile: !state,
  };
};
