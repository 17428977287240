import { Container, withFilters } from "@inlet/react-pixi/animated";
import { animated } from "@react-spring/web";
import React from "react";
// import { RGBSplitFilter } from "@pixi/filter-rgb-split";
import { MyFilter } from "../filters/MyFilter";
import { configs } from "../data/configs";

const Filters3 = withFilters(Container, {
  // rgbSplit: RGBSplitFilter,
  f: MyFilter,
});

function P3Groups({ x, m, d, children }) {
  const deltaX = m * 130 * d;

  return (
    <Filters3
      f={{
        delta: deltaX * -0.005 * configs.deformation,
        red: [deltaX * configs.rgbSplit, 0],
      }}
      // rgbSplit={{
      //   construct: [
      //     [0, 0],
      //     [0, 0],
      //     [0, 0],
      //   ],
      //   red: [deltaX * configs.rgbSplit, 0],
      // }}
      x={x}
    >
      {children}
    </Filters3>
  );
}

export default animated(P3Groups);
