import { Container, Sprite } from "@inlet/react-pixi/animated";
import { animated } from "@react-spring/web";
import React, { useCallback, useContext, useMemo } from "react";
import { MouseContext } from "../contexts/mouseContext";
import PIXI from "../PIXI";
import { attachd } from "./MouseAttach";

const PSoundInner = animated(
  ({
    progress = 0,
    direction = 1,
    pointerover,
    pointerout,
    x,
    y,
    attachX,
    attachY,
    attached,
    pointertap,
    muted,
    ...rest
  }) => {
    const hitArea = useMemo(() => {
      const g = new PIXI.Rectangle(-20, -20, 40, 40);

      return g;
    }, []);
    return (
      <Container
        pointerover={pointerover}
        pointerout={pointerout}
        interactive={true}
        buttonMode={true}
        hitArea={hitArea}
        x={x}
        y={y}
        pointertap={pointertap}
      >
        <Sprite
          x={attachX - x}
          y={attachY - y}
          image={muted ? "./icons/sound_colse.png" : "./icons/sound_on.png"}
          anchor={0.5}
        />
      </Container>
    );
  }
);

export const PBtnSound = attachd(
  React.memo(({ pointertap, ...props }) => {
    const { ovrideMouseStyle, mouseBreatheRef } = useContext(MouseContext);
    const handleMouseOver = useCallback(() => {
      ovrideMouseStyle({
        size: 30,
      });
      props.attach(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleMouseOut = useCallback(() => {
      ovrideMouseStyle(null);
      props.attach(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePointerTap = useCallback(() => {
      mouseBreatheRef.current?.(0.8);
      pointertap?.();
    }, [mouseBreatheRef, pointertap]);

    return (
      <PSoundInner
        {...props}
        pointertap={handlePointerTap}
        pointerover={handleMouseOver}
        pointerout={handleMouseOut}
      />
    );
  })
);
