import { Container, Sprite } from "@inlet/react-pixi/animated";
import { animated } from "@react-spring/web";
import React, { useCallback, useContext, useMemo } from "react";
import { MouseContext } from "../contexts/mouseContext";
import PIXI from "../PIXI";
import { attachd } from "./MouseAttach";

const PBtnLogoInner = animated(
  ({
    progress = 0,
    direction = 1,
    pointerover,
    pointerout,
    x,
    y,
    attachX,
    attachY,
    attached,
    pointertap,
    width,
    height,
    alpha,
    ...rest
  }) => {
    const hitArea = useMemo(() => {
      const g = new PIXI.Rectangle(
        (-width / 2) * 2,
        (-height / 2) * 3,
        width * 2,
        height * 3
      );

      return g;
    }, []);
    return (
      <Container
        pointerover={pointerover}
        pointerout={pointerout}
        interactive={true}
        buttonMode={true}
        hitArea={hitArea}
        x={x}
        y={y}
        pointertap={pointertap}
      >
        <Sprite
          x={attachX - x}
          y={attachY - y}
          width={width}
          height={height}
          source={"best-innovation-white.png"}
          anchor={0.5}
          alpha={alpha}
        />
      </Container>
    );
  }
);

export const PBtnInnovation = attachd(
  React.memo(({ pointertap, ...props }) => {
    const { ovrideMouseStyle, mouseBreatheRef } = useContext(MouseContext);
    const handleMouseOver = useCallback(() => {
      ovrideMouseStyle({
        size: 50,
      });
      props.attach(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleMouseOut = useCallback(() => {
      ovrideMouseStyle(null);
      props.attach(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePointerTap = useCallback(() => {
      mouseBreatheRef.current?.(0.8);
      pointertap?.();
    }, [mouseBreatheRef, pointertap]);

    return (
      <PBtnLogoInner
        {...props}
        pointertap={handlePointerTap}
        pointerover={handleMouseOver}
        pointerout={handleMouseOut}
      />
    );
  }),
  50,
  50
);
