import { Filter } from "@pixi/core";

const v2 = `attribute vec2 aVertexPosition;
attribute vec2 aTextureCoord;
uniform mat3 projectionMatrix;
varying vec2 vTextureCoord;
void main(void)
{
    gl_Position = vec4((projectionMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);
    vTextureCoord = aTextureCoord;
}
`;

const fragment = `precision mediump float;

varying vec2 vTextureCoord;
uniform sampler2D uSampler;
uniform float delta;
uniform vec4 filterArea;
uniform vec2 red;

void main(void)
{

    vec2 uv = vec2(vTextureCoord.x + ((sin(vTextureCoord.y * 3.1415926535897932384626433832795) * delta * 2.0) * 0.125), vTextureCoord.y);


    gl_FragColor = texture2D(uSampler, uv);
    gl_FragColor.r = texture2D(uSampler, vTextureCoord + red/filterArea.xy).r;
}`;

class MyFilter extends Filter {
  constructor(delta = 0.0, red = [-10, 0]) {
    super(v2, fragment, { delta: 0.0, red });
    this.red = red;
    this.delta = delta;
  }

  get delta() {
    return this.uniforms.delta;
  }
  set delta(value) {
    this.uniforms.delta = value;
  }
  get red(): Offset {
    return this.uniforms.red;
  }
  set red(value: Offset) {
    this.uniforms.red = value;
  }
}

export { MyFilter };
