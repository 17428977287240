import { Container, withFilters } from "@inlet/react-pixi/animated";
import { animated } from "@react-spring/web";
import React, { useRef } from "react";
// import { RGBSplitFilter } from "@pixi/filter-rgb-split";
import { MyFilter } from "../filters/MyFilter";

const Filters3 = withFilters(Container, {
  // rgbSplit: RGBSplitFilter,
  f: MyFilter,
});

function P3GroupsF({ x, y, children, deltaXOverwride }) {
  const prevLeftRef = useRef(0);
  const oldVal = prevLeftRef.current;
  const newVal = x;

  prevLeftRef.current = newVal;
  const deltaX = oldVal - newVal;

  return (
    <Filters3
      f={{
        delta: deltaXOverwride ? deltaXOverwride * -0.005 : deltaX * -0.005,
        red: [(deltaXOverwride ? deltaXOverwride * 1 : deltaX) * 0.1, 0],
      }}
      // rgbSplit={{
      //   construct: [
      //     [0, 0],
      //     [0, 0],
      //     [0, 0],
      //   ],
      //   red: [deltaXOverwride ? deltaXOverwride * 1 : deltaX, 0],
      // }}
      // x={x}
      // y={y}
    >
      {children}
    </Filters3>
  );
}

export default animated(P3GroupsF);
