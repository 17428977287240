import { Graphics } from "@inlet/react-pixi";
import { animated } from "@react-spring/web";
import { useCallback } from "react";

const PProgressCircle = animated(
  ({
    progress = 1,
    size = 15,
    color = 0xaf0a0a,
    paddingX = 0,
    paddingY = 0,
    opacity = 1,
  }) => {
    const draw = useCallback(
      (g) => {
        var radius = size;
        var endPercent = 87;
        // var curPerc = 0;
        // var counterClockwise = false;
        var circ = Math.PI * 2;
        var quart = Math.PI - 0.4;

        g.clear();
        g.lineStyle(1, color, opacity);

        // g.beginFill(0xff3300);
        // g.beginPath();
        g.arc(
          paddingX,
          paddingY,
          radius,
          -quart,
          (circ * progress * endPercent) / 100 - quart,
          false
        );

        g.lineStyle(1, 0xffffff, opacity);

        g.arc(
          paddingX,
          paddingY,
          radius,
          (circ * progress * endPercent) / 100 - quart,
          quart,
          false
        );
        // g.endFill();
        // g.stroke();
      },
      [size, color, opacity, paddingX, paddingY, progress]
    );

    return <Graphics preventRedraw={true} draw={draw} />;
  }
);

export default PProgressCircle;
