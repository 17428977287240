import { Graphics } from "@inlet/react-pixi/animated";
import { animated } from "@react-spring/web";
import React, { useCallback } from "react";

function PProgressLine({ size, percentage = 0, y, x = 0 }) {
  const draw = useCallback(
    (g) => {
      g.clear();
      g.lineStyle(1, 0xaf0a0a, 1);
      g.moveTo(0, 0);
      g.lineTo(percentage * size, 0);
      g.lineStyle(1, 0xffffff, 1);
      g.lineTo(size, 0);
    },
    [percentage, size]
  );
  return <Graphics y={y} x={x} draw={draw} />;
}

export default animated(PProgressLine);
