import { PixiComponent } from "@inlet/react-pixi/animated";
import * as PIXI from "pixi.js";

import React from "react";
import { clamp } from "gsap/gsap-core";

export default React.memo(
  PixiComponent("PixiSlider", {
    create: (props) => {
      const instance = new PIXI.Container();
      const slide = new PIXI.Graphics();

      const circle = new PIXI.Graphics();

      const drawCircle = (newProgres) => {
        try {
          const width = instance._dWidth;
          circle.clear();

          circle.beginFill(0xffffff);

          circle.drawCircle(width * newProgres, 0, 5);
          circle.endFill();

          circle.endFill();
          circle.hitArea = new PIXI.Rectangle(
            width * newProgres - 20,
            -20,
            40,
            40
          );
        } catch (e) {}
      };

      const drawSlide = (newProgres) => {
        try {
          const width = instance._dWidth;
          const height = instance._dHeight;
          slide.clear();
          slide.beginFill(0x606060);
          slide.drawRect(0, 0, width, height);
          slide.endFill();
          slide.beginFill(0xffffff);
          slide.drawRect(0, 0, width * newProgres, height);

          slide.endFill();
          slide.hitArea = new PIXI.Rectangle(0, -12, width, 24);
        } catch (e) {}
      };

      const draw = (newProgres) => {
        try {
          const width = instance._dWidth;

          drawSlide(newProgres);
          drawCircle(newProgres);

          if (instance.drawCb) {
            instance.drawCb(width * newProgres, instance.dragging, newProgres);
          }
        } catch (e) {}
      };
      instance.addChild(slide);
      instance.addChild(circle);

      instance.drawSlider = draw;

      Object.defineProperty(instance, "progress", {
        set: function (newProgres) {
          instance._vProgress = newProgres;
          if (instance.dragging) {
            return;
          }

          if (instance) {
            instance.drawSlider(newProgres);
          }
        },
        get: () => {
          return instance._vProgress;
        },
      });

      const onDragStart = (event) => {
        instance.data = event.data;
        instance.dragging = true;
        // const newPosition = instance.data.getLocalPosition(instance.parent);
        // instance.x = newPosition.x;
        // if (instance.x > max) instance.x = max;
        // if (instance.x < min) instance.x = min;
        // onChange?.({ x: instance.x, progress: newPosition.x / width });

        // instance.drawSlider(newPosition.x / width);
      };

      const onDragEnd = (event) => {
        const width = instance._dWidth;

        try {
          const newPosition = instance.data.getLocalPosition(instance.parent);

          if (newPosition) {
            instance._vProgress = clamp(0, 1, newPosition.x / width);
            instance.props.onChange?.({
              progress: clamp(0, 1, newPosition.x / width),
            });
          }
        } catch (e) {}

        delete instance.data;
        instance.dragging = false;
      };

      const onDragMove = (event) => {
        const width = instance._dWidth;

        if (instance.dragging) {
          try {
            const newPosition = instance.data.getLocalPosition(instance.parent);
            // instance.x = newPosition.x;
            // if (instance.x > max) instance.x = max;
            // if (instance.x < min) instance.x = min;
            // onChange({ x: instance.x });
            instance._vProgress = newPosition.x / width;

            instance.props.onChange?.({
              progress: clamp(0, 1, newPosition.x / width),
            });
            instance.drawSlider(clamp(0, 1, newPosition.x / width));
          } catch (e) {}
        }
      };

      instance.on("pointerdown", onDragStart);
      instance.on("pointerup", onDragEnd);
      instance.on("pointerupoutside", onDragEnd);
      instance.on("pointermove", onDragMove);

      instance.interactiveChildren = true;

      circle.interactive = true;
      slide.interactive = true;

      circle.on("pointerover", () => {
        instance.isPointerover = true;
        setTimeout(() => {
          props.ovrideMouseStyle({ size: 75 / 2 });
        }, 100);
      });
      circle.on("pointerout", () => {
        instance.isPointerover = false;

        props.ovrideMouseStyle(null);
      });

      slide.on("pointerover", () => {
        instance.isPointerover = true;
        // props.ovrideMouseStyle({ size: 75 / 2 });

        props.setType("control");

        // const pos = instance.getGlobalPosition();
        // props.ovrideMouseStyle({
        //   size: 75 / 2,
        //   lock: true,
        //   x: pos.x + instance._dWidth * instance.progress,
        //   y: pos.y,
        // });
      });
      slide.on("pointerout", () => {
        instance.isPointerover = false;
        props.setType("default");
        // props.ovrideMouseStyle(null);
      });
      return instance;
    },
    applyProps: (instance, oldProps, newProps) => {
      const { width, progress = 0 } = newProps;
      const height = 1;

      instance._dWidth = width;
      instance._dHeight = height;

      instance.drawSlider(progress);

      instance.interactive = true;
      instance.buttonMode = true;

      instance.props = newProps;

      /*
    const onDragStart = (event) => {
      instance.data = event.data;
      instance.dragging = true;
    };

    const onDragEnd = (event) => {
      delete instance.data;
      instance.dragging = false;
    };

    const onDragMove = (event) => {
      const min = 0;
      // parent._width is the width of the background, and width is the width of this button
      const max = instance.parent._width - width;
      if (instance.dragging) {
        const newPosition = instance.data.getLocalPosition(instance.parent);
        instance.x = newPosition.x;
        if (instance.x > max) instance.x = max;
        if (instance.x < min) instance.x = min;
        onChange({ x: instance.x });
      }
    };

    instance.clear();
    instance.beginFill(fill);
    instance.drawRect(0, 0, width, height);
    instance.endFill();
    instance.interactive = true;
    instance.buttonMode = true;
    instance.on("pointerdown", onDragStart);
    instance.on("pointerup", onDragEnd);
    instance.on("pointerupoutside", onDragEnd);
    instance.on("pointermove", onDragMove);

    if (oldProps.moveToX && oldProps.moveToX !== moveToX) {
      instance.x = 0;
      instance.x = moveToX;
      // Callback
      onChange({ x: instance.x });
    }

    if (!oldProps.currentPosition) {
      // Only when mounting the component
      instance.x = currentPosition * stepWidth;
    }

    if (oldProps.scale && oldProps.scale !== scale) {
      instance.x = 0;
      instance.x = currentPosition * stepWidth;
    }
    */
    },
  }),
  (prevProps, nextProps) => {
    if (prevProps.width !== nextProps.width) {
      return false;
    }

    return true;
  }
);

// export default PixiComponent("PixiHtmlText", Text);
