import { Container } from "@inlet/react-pixi";
import { Container as ContainerA } from "@inlet/react-pixi/animated";
import { Text } from "@inlet/react-pixi";
import { animated, useSpring } from "@react-spring/web";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { MouseContext } from "../contexts/mouseContext";
import PIXI from "../PIXI";
import { attachd } from "./MouseAttach";

const PBtnProgressTextInner = animated(
  ({
    progress = 0,
    direction = 1,
    pointerover,
    pointerout,
    x,
    y,
    attachX,
    attachY,
    attached,
    pointertap,
    text,
    textStyle,
    ...rest
  }) => {
    const hitArea = useMemo(() => {
      // const origionalSize = 32;
      // const paddingY = -origionalSize / 2;
      const targetSize = 80;
      // const extraSize = (targetSize - origionalSize) / 2;
      const g = new PIXI.Rectangle(
        -targetSize / 2,
        -targetSize / 2,
        targetSize,
        targetSize
      );

      return g;
    }, []);

    return (
      <Container
        pointerover={pointerover}
        pointerout={pointerout}
        interactive={true}
        buttonMode={true}
        hitArea={hitArea}
        x={x}
        y={y}
        pointertap={pointertap}
      >
        <Container x={attachX - x} y={attachY - y}>
          <Text text={text} style={textStyle} anchor={0.5} />
        </Container>
      </Container>
    );
  }
);

export const PBtnProgressText = attachd(
  React.memo(({ pointertap, ...props }) => {
    const [isHover, setIsHover] = useState(false);

    const { ovrideMouseStyle, mouseBreatheRef } = useContext(MouseContext);
    const handleMouseOver = useCallback(() => {
      setIsHover(true);
      ovrideMouseStyle({
        size: 61.5,
      });
      props.attach(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleMouseOut = useCallback(() => {
      setIsHover(false);
      ovrideMouseStyle(null);
      props.attach(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [progressStyle] = useSpring(
      {
        progress: isHover ? 1 : 0,
      },
      [isHover]
    );
    const handlePointerTap = useCallback(() => {
      mouseBreatheRef.current?.(0.8);
      pointertap?.();
    }, [mouseBreatheRef, pointertap]);

    return (
      <React.Fragment>
        <PBtnProgressTextInner
          {...props}
          pointertap={handlePointerTap}
          pointerover={handleMouseOver}
          pointerout={handleMouseOut}
          // interactive={true}
          // buttonMode={true}
          progress={progressStyle.progress}
        />

        <ContainerA alpha={progressStyle.progress.to([0, 1], [1, 0])}>
          <Text
            text={props.subText}
            x={props.subTextX}
            y={props.subTextY}
            style={props.textStyle}
          />
        </ContainerA>
      </React.Fragment>
    );
  }),
  40,
  40
);
