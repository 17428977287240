import { Text } from "@inlet/react-pixi";
import { Container } from "@inlet/react-pixi/animated";
import { TextStyle } from "@pixi/text";
import React from "react";

const style = new TextStyle({
  fontFamily: "Noto Sans TC",
  fill: 0xffffff,
  fontWeight: 350,
  fontSize: 29,
});

const styleMobile = new TextStyle({
  fontFamily: "Noto Sans TC",
  fill: 0xffffff,
  fontWeight: 350,
  fontSize: 25,
});

function PIntroText({ windowSize, progress, isMobile }) {
  return (
    <Container
      x={windowSize.width / 2}
      y={windowSize.height / 2}
      alpha={progress.to([0, 0.3, 0.6, 1], [0, 1, 1, 0])}
    >
      <Text
        text="有些事，認真就輸了嗎?"
        style={isMobile ? styleMobile : style}
        anchor={0.5}
        isSprite={true}
      />
    </Container>
  );
}

export default PIntroText;
