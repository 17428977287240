import kv01 from "./3dData/kv01.json";
import kv02 from "./3dData/kv02.json";
import kv03 from "./3dData/kv03.json";
import kv04 from "./3dData/kv04.json";

const mobilePlayX = 290;

const testEl = document.createElement("video");
const isWebmSupport = testEl.canPlayType('video/webm; codecs="vp9, vorbis"');
const videoFileType = isWebmSupport ? ".webm" : "d.mp4";

export const videoList = [
  {
    title: "Tears",
    subTitle: "認真去活",
    hashTag: "疫情篇",
    menuTitle: "episode 1",
    photo3d: kv01,
    // imagePrefix: `sources/KV1/images_KV1_OK_${size}`,
    key: `kv01`,
    imagePrefix: `kv01`,
    video: `videos/KV1${videoFileType}`,
    cover: "episode/KV1.png",
    mobilePlayX: mobilePlayX,

    cameraLimit: {
      minX: -9,
      maxX: 9,
      minY: -3.5,
      maxY: 3,
    },
    cameraLayerSize: {
      width: 736.38,
      height: 385,
    },
  },
  {
    title: "Sweat",
    subTitle: "認真去活",
    hashTag: "職場篇",
    menuTitle: "episode 2",
    photo3d: kv02,

    // imagePrefix: `sources/KV2/images_KV2_OK_${size}`,
    key: `kv02`,
    imagePrefix: `kv02`,
    video: `videos/KV2${videoFileType}`,
    cover: "episode/KV2.png",
    mobilePlayX: mobilePlayX,

    cameraLimit: {
      minX: -15,
      maxX: 15,
      minY: -15,
      maxY: 13,
    },
    cameraLayerSize: {
      width: 736.38,
      height: 414.214,
    },
  },
  {
    title: "Milk",
    subTitle: "認真去活",
    hashTag: "家庭篇",
    menuTitle: "episode 3",

    photo3d: kv03,
    // imagePrefix: `sources/KV3/images_KV3_OK_${size}`,
    key: `kv03`,
    imagePrefix: `kv03`,
    video: `videos/KV3${videoFileType}`,
    cover: "episode/KV3.png",
    mobilePlayX: mobilePlayX,

    cameraLimit: {
      minX: -7,
      maxX: 7,
      minY: -5,
      maxY: 5,
    },
    cameraLayerSize: {
      width: 736.38,
      height: 385,
    },
  },
  {
    title: "Blood",
    subTitle: "認真去活",
    hashTag: "夢想篇",
    menuTitle: "episode 4",
    photo3d: kv04,
    // imagePrefix: `sources/KV4/images_KV4_OK_${size}`,
    key: `kv04`,
    imagePrefix: `kv04`,
    video: `videos/KV4${videoFileType}`,
    cover: "episode/KV4.png",
    mobilePlayX: mobilePlayX,

    cameraLimit: {
      minX: -7,
      maxX: 4.5,
      minY: -3.8,
      maxY: 3.8,
    },
    cameraLayerSize: {
      width: 736.38,
      height: 385,
    },
  },
];

export const menuList = [
  ...videoList.map((v) => {
    return {
      menuTitle: v.menuTitle,
      key: v.key,
      cover: v.cover,
      video: v.video,
    };
  }),
  {
    menuTitle: "完整版",
    key: "full",
    cover: "episode/all.png",
    video: `videos/all${videoFileType}`,
  },
];
