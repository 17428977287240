// import { Text, Container } from "@inlet/react-pixi";
import { Text } from "@inlet/react-pixi";
import { Container as ContainerA, Graphics } from "@inlet/react-pixi/animated";
import { TextStyle } from "@pixi/text";

import React, { useCallback, useContext, useRef } from "react";

import { animated, useSpring } from "@react-spring/web";
import { MouseContext } from "../contexts/mouseContext";

// const Rectangle = PixiComponent("Rectangle", {
//   create: () => new PIXI.Graphics(),
//   applyProps: (g, _, props) => {
//     const { fill, x, y, width, height } = props;

//     g.clear();
//     g.beginFill(fill);
//     g.drawRect(x, y, width, height);
//     g.endFill();
//   },
// });

const styleSmall = new TextStyle({
  // align: "left",
  // fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
  fontSize: 17,
  // fontWeight: 400,
  fill: ["#ffffff"], // gradient
  fontFamily: "Noto Sans TC",
  fontWeight: 300,
});

const styleHashTagText = new TextStyle({
  // align: "left",
  // fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
  fontSize: 17,
  // fontWeight: 400,
  fill: "#ffffff",
  fontFamily: "Noto Sans TC",
  fontWeight: 300,
  lineHeight: 1,
});

const styleHashTag = new TextStyle({
  // align: "left",
  // fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
  fontSize: 12,
  // fontWeight: 400,
  fill: ["#ffffff"], // gradient
  fontFamily: "Noto Sans TC",
  fontWeight: 300,
});

const styleBig = new TextStyle({
  fontFamily: "Noto Sans TC",
  fontSize: 50,
  fontWeight: 500,
  fill: ["#ffffff"], // gradient
});

function PTitleMobile({
  x,
  y,
  showSize,
  opacity = 1,
  opacity2 = 1,
  title,
  subTitle,
  interactive,
  isMovingRef,
  children,
  pointertap,
  hashTag,
}) {
  const [style, api] = useSpring(() => ({
    scale: 1,
  }));

  const { setType } = useContext(MouseContext);
  const handlePointerOver = useCallback(() => {
    setType("progress");
    api.start({
      to: {
        scale: 1.1,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePointerOut = useCallback(() => {
    setType("default");
    api.start({
      to: {
        scale: 1,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleClick = useCallback(() => {
  //   setType("play");
  //   setShowMask((p) => 1);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const containerRef = useRef();
  const bigTextRef = useRef();
  // useEffect(() => {
  //   const width = bigTextRef.current.width;

  //   containerRef.current.pivot.x = width / 2;
  // }, []);
  const draw = useCallback((g) => {
    g.clear();
    g.lineStyle(1, 0xffffff, 1);
    g.lineTo(50, 0);
  }, []);

  return (
    <ContainerA
      x={x}
      y={y}
      anchor={0.5}
      scale={style.scale}
      ref={containerRef}
      alpha={opacity}
      interactive={interactive}
      buttonMode={true}
      pointerover={handlePointerOver}
      pointerout={handlePointerOut}
      pointertap={pointertap}
    >
      <Text text={subTitle} style={styleSmall} isSprite={true} />
      <Text text="＃" style={styleHashTag} isSprite={true} x={74} y={3} />

      <Text text={hashTag} style={styleHashTagText} isSprite={true} x={86} />
      <Graphics draw={draw} x={86} y={21} />

      <Text
        ref={bigTextRef}
        y={26}
        text={title}
        style={styleBig}
        isSprite={true}
      />
      {children}
    </ContainerA>
  );
}
export default animated(PTitleMobile);
