import {
  useChain,
  useSpring,
  useSpringRef,
  useSprings,
} from "@react-spring/core";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { usePrevious } from "react-use";
import { MouseContext } from "../contexts/mouseContext";
import { videoList } from "../data/videoList";
import { addGUIGroup } from "../components/datGUI";

export const useVideoListControl = ({
  windowSize,
  pages,
  pause,
  isLoading,
  gap,
}) => {
  const mouseContext = useContext(MouseContext);
  const [playVideo, setPlayVideo] = useState(false);
  const playVideoRef = useRef(playVideo);
  playVideoRef.current = playVideo;

  const [moveInfo, setMoveInfo] = useState({
    page: -1,
    direction: 1,
    renderAll: false,
    immediate: false,
  });
  const moveInfoRef = useRef(moveInfo);
  moveInfoRef.current = moveInfo;

  const moveInfoP = usePrevious(moveInfo);

  useEffect(() => {
    if (isLoading === false) {
      setTimeout(() => {
        setMoveInfo({
          page: 0,
          direction: -1,
          renderAll: false,
          immediate: false,
        });
      }, 3600);
    }
  }, [isLoading]);

  const [isMoving, setIsMoving] = useState(false);

  const handleMovingStart = useCallback(() => {
    setIsMoving(true);
  }, []);
  const handleMovingEnd = useCallback(() => {
    setIsMoving(false);
  }, []);
  const pageStpingRef = useSpringRef();
  const [config, setConfig] = useState({
    mass: 10,
    friction: 80,
    tension: 170,
  });

  useEffect(() => {
    const configs = {
      mass: 10,
      tension: 170,
      friction: 90,
    };
    addGUIGroup("spring", (gui2) => {
      gui2.add(configs, "mass", 0, 300, 0.1).onFinishChange((mass) => {
        setConfig((p) => ({ ...p, mass }));
      });
      gui2.add(configs, "tension", 0, 300, 0.1).onFinishChange((tension) => {
        setConfig((p) => ({ ...p, tension }));
      });
      gui2.add(configs, "friction", 0, 300, 0.1).onFinishChange((friction) => {
        setConfig((p) => ({ ...p, friction }));
      });
    });
  }, []);

  const [{ x, direction, page }] = useSpring(
    {
      ref: pageStpingRef,
      config: config,
      from: {
        page: 0,
        x: 0,
        direction: 1,
        config: config,
      },
      to: {
        page: moveInfo.page,
        x: -(moveInfo.page * windowSize.width + moveInfo.page * gap),
        direction: moveInfo.direction,
      },
      immediate: moveInfo.immediate ? true : ["direction"],
      onStart: handleMovingStart,
      onResolve: handleMovingEnd,
    },
    [moveInfo, windowSize.width]
  );

  const progress = page.to((p) =>
    p > moveInfo.page ? p - moveInfo.page : moveInfo.page - p
  );

  // const titleWidth = 730;
  const g = windowSize.width / 2;
  // const tGap = titleWidth + (g - titleWidth / 3);
  const tGap = windowSize.width;

  const [titlesProps] = useSprings(
    pages,
    videoList.map((_, ti) => ({
      x: g + (ti - moveInfo.page) * tGap,
      opacity: ti === moveInfo.page ? 1 : 0.5,
      config: { mass: 5, friction: 70 },
    })),
    [moveInfo, g]
  );

  const setPage = useCallback((i, direction) => {
    if (i === -1) {
      setMoveInfo({
        page: videoList.length - 1,
        direction: 1,
        renderAll: true,
        immediate: false,
      });
    } else if (i === videoList.length) {
      setMoveInfo({
        page: 0,
        direction: -1,
        renderAll: true,
        immediate: false,
      });
    } else {
      setMoveInfo({
        page: i,
        direction: direction,
        renderAll: false,
        immediate: false,
      });
    }
  }, []);

  const progressSpringRef = useSpringRef();

  const [{ runningFullProgress }] = useSpring(
    {
      ref: progressSpringRef,
      from: {
        runningFullProgress: moveInfo.page,
      },
      to: {
        runningFullProgress: moveInfo.page + 1,
      },
      config: {
        duration: 15000,
      },
      pause: mouseContext.showMask >= 1 || pause,
      reset: moveInfoP ? moveInfoP.page !== moveInfo.page : false,
      onResolve: (result) => {
        if (result.finished) {
          setMoveInfo((prev) => {
            return {
              page: result.value < 4 ? result.value : 0,
              direction: result.value === 4 ? -1 : 1,
              renderAll: result.value === 4,
              immediate: false,
            };
          });
        }
      },
    },
    [moveInfo, mouseContext.showMask, pause]
  );

  useChain([pageStpingRef, progressSpringRef], [0, 0]);

  const runningProgress = runningFullProgress.to((p) =>
    p > moveInfo.page ? p - moveInfo.page : moveInfo.page - p
  );

  const maskSpringRef = useSpringRef();
  const [maskSpring] = useSpring(
    {
      ref: maskSpringRef,
      progress: mouseContext.showMask ? 1 : 0,
      config: { mass: 10, friction: 60 },
      delay: 500,
      immediate: mouseContext.showMask === 2,
    },
    [mouseContext.showMask]
  );

  const titleFadeSpringRef = useSpringRef();
  const [titleFadeSpring] = useSpring(
    {
      ref: titleFadeSpringRef,
      progress: mouseContext.showMask ? 1 : 0,
      config: { mass: 10, friction: 60 },

      onResolve: (result) => {
        if (result.finished) {
          if (result.value === 1 && !playVideoRef.current) {
            setPlayVideo(videoList[moveInfo.page].video);
          }
        }
      },
    },
    [mouseContext.showMask, moveInfo]
  );

  useChain(
    mouseContext.showMask
      ? [maskSpringRef, titleFadeSpringRef]
      : [titleFadeSpringRef, maskSpringRef],
    mouseContext.showMask ? [0, 1.5] : [0, 0.8]
  );

  const handleExitVideo = useCallback(() => {
    const currentVideo = playVideoRef.current;
    setPlayVideo(false);
    mouseContext.setShowMask(0);
    if (videoList[moveInfoRef.current.page].video !== currentVideo) {
      const index = videoList.findIndex((d) => d.video === currentVideo);
      if (index !== -1) {
        handleJumpPageDirect(index);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNextPage = useCallback(() => {
    setMoveInfo((prev) => {
      const newValue = prev.page + 1;
      return {
        page: newValue < 4 ? newValue : 0,
        direction: newValue === 4 ? -1 : 1,
        renderAll: newValue === 4,
        immediate: false,
      };
    });
  }, []);

  const handlePrevPage = useCallback(() => {
    setMoveInfo((prev) => {
      const newValue = prev.page - 1;
      return {
        page: newValue !== -1 ? newValue : videoList.length - 1,
        direction: newValue !== -1 ? -1 : 1,
        renderAll: newValue === -1,
        immediate: false,
      };
    });
  }, []);

  const handleJumpPage = useCallback((newPage) => {
    setMoveInfo((prev) => {
      const delta = newPage - prev.page;
      const newValue = newPage;

      setTimeout(() => {
        mouseContext.setType("play");
        mouseContext.setShowMask((p) => 1);
      }, Math.abs(delta * 500) + 1000);
      return {
        page: newValue,
        direction: delta > 0 ? 1 : -1,
        renderAll: Math.abs(delta) > 1,
        immediate: false,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleJumpPageDirect = useCallback((newPage) => {
    setMoveInfo((prev) => {
      const delta = newPage - prev.page;
      const newValue = newPage;

      return {
        page: newValue,
        direction: delta > 0 ? 1 : -1,
        renderAll: Math.abs(delta) > 1,
        immediate: true,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    page: moveInfo.page,
    direction: moveInfo.direction,
    renderAll: moveInfo.renderAll,
    titlesProps,
    setPage,
    springs: {
      x,
      progress,
      direction,
    },
    runningProgress,
    runningFullProgress,
    maskSpring,
    titleFadeSpring,
    playVideo,
    handleExitVideo,
    handleNextPage,
    handlePrevPage,
    isMoving,
    setPlayVideo,
    handleJumpPage,
  };
};
