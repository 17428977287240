import { useEffect, useState } from "react";
import { videoList } from "../data/videoList";
import PIXI from "../PIXI";

export const useLoader = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [progress, serProgres] = useState(0);
  useEffect(() => {
    const loader = PIXI.Loader.shared;
    loader.reset();
    loader.add({
      name: "google font",
      url: "https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500&display=swap",
      metadata: {
        testString: videoList.reduce((acc, val) => {
          acc = acc + val.subTitle + val.title + val.menuTitle;
          return acc;
        }, ""),
        timeout: 30 * 1000,
      },
    });
    videoList.forEach((v) => {
      v.photo3d.forEach((p) => {
        loader.add(`${v.imagePrefix}/${p.file}`);
      });
      loader.add(v.video);
    });
    loader.onProgress.add((event) => {
      serProgres(event.progress);
    });
    loader.onComplete.add(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 800);
    });
    loader.load();
  }, []);

  return { isLoading, progress };
};
